@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.singleInputContainer {
  display: flex;
  width: 100%;

  @include responsiveProperty(
    (
      "margin-bottom": 24,
      "gap": 24,
    )
  );
}

.inputLabel {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  span {
    color: $RED;
  }
}

.customInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.phoneInput {
  width: 100%;
  box-sizing: border-box;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  display: flex;
  align-items: center;

  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  padding-left: 22px;

  @include responsiveProperty(
    (
      "height": 36,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 20,
      "padding-left": 20,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.save {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 20,
      "padding-left": 20,
    )
  );
}

.btnContainer {
  display: flex;
  justify-content: end;
  gap: 10px;

  // @include responsiveProperty(
  //   (
  //     "margin-bottom": 50,
  //   )
  // );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .singleInputContainer {
    gap: calculatePixels(1200, 24) !important;
    margin-bottom: calculatePixels(1200, 24) !important;
  }

  .inputLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .phoneInput {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 39) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }

  .cancel {
    padding-top: calculatePixels(1200, 20) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-right: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 20) !important;
  }

  .save {
    padding-top: calculatePixels(1200, 20) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-right: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 20) !important;
  }

  // .btnContainer{
  //   margin-bottom: calculatePixels(1200, 50) !important;
  // }
}

@media screen and (max-width: 650px) {
  .singleInputContainer {
    display: block !important;
    margin-bottom: 0px !important;
  }

  #inputStyle,
  .customInputContainer {
    margin-bottom: 20px;
  }
}
