@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.optionsContainer {
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  width: 300px;
  top: 20px;
  right: 0px;
  cursor: pointer;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: #f2f2f2 !important;
  }

  &::-webkit-scrollbar {
    width: 20px !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: $LIGHT_RED !important;
  }
}

.openSelectionContainer {
  display: flex;
  z-index: 99999;
}

.labelStyle {
  font-weight: 500;
  font-family: "Manrope";
  color: black;
  cursor: pointer;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.labelStyle2 {
  font-size: 12px;
  font-family: PoppinsMedium;
  color: black;
  width: 100%;
  cursor: pointer;
}

.avtStyle {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  object-fit: cover;
}

.iconWrapper {
  width: 40px;
  height: 40px;

  border-radius: 50%;
  margin-right: 12px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 50%;
  }
}

.optionContainer {
  border-bottom: 1px solid $LIGHT_GRAY;
  cursor: pointer;

  &:hover,
  &:hover > .labelStyle {
    background-color: $LIGHT_RED;
  }
}

.topradius {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.btmradius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.labelContainer {
  display: flex;
  // flex-direction: column;
  padding: 10px;
  width: 100%;
  align-items: center;
  gap: 15px;
}

.profile_pic {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
