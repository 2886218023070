@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  min-height: 90%;
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.heading {
  color: $GRAY;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.requestHeading {
  color: $DARK_GRAY;
  font-weight: 400;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.requestSubHeading {
  color: $GRAY;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-top": 8,
    )
  );
}

.textarea {
  font-weight: 400;
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  resize: none !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding": 14,
      "height": 120,
      "margin-top": 10,
    )
  );
}

.textarea:focus-visible {
  outline: none;
}

.btn {
  font-weight: 500;
  width: fit-content;
  @include responsiveProperty(
    (
      "margin-top": 16,
      "font-size": 14,
      "line-height": 22,
      "padding-top": 7,
      "padding-bottom": 7,
      "padding-left": 16,
      "padding-right": 16,
    )
  );
}

.requestContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.errorBorder {
  border: 1px solid $RED;
}

.btnContainer {
  display: flex;
  justify-content: end;
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.reviewContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "padding": 24,
      "margin-right": 10,
    )
  );
}

.feedbackbtn {
  color: $DARK_GRAY;
  border: 1px solid $LIGHT_GRAY2 !important;
  background-color: $LIGHT_GRAY !important;
}

.reviewtHeading {
  color: $DARK_GRAY;
  font-weight: 300;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.reviewtSubHeading {
  color: $GRAY;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-top": 8,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .requestHeading,
  .reviewtHeading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .requestSubHeading,
  .reviewSubHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    margin-top: calculatePixels(1200, 8) !important;
  }
  .btn {
    margin-top: calculatePixels(1200, 16) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    padding-top: calculatePixels(1200, 7) !important;
    padding-bottom: calculatePixels(1200, 7) !important;
    padding-left: calculatePixels(1200, 16) !important;
    padding-right: calculatePixels(1200, 16) !important;
  }
  .requestContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .textarea {
    padding: calculatePixels(1200, 14) !important;
    height: calculatePixels(1200, 120) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .error {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .reviewContainer {
    padding: calculatePixels(1200, 24) !important;
    margin-right: calculatePixels(1200, 10) !important;
  }
}

@media screen and (max-width: 900px) {
  .mainGrid {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  #reviewContainer {
    margin-right: 0px !important;
    margin-top: 20px !important;
  }
}
