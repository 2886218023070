@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

html {
  background-color: #fafafa;
}

html {
  font-family: "Manrope";
}

html::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

/*For Firefox*/
html {
  scrollbar-width: none !important;
}

/*this is for sidebar to make it white*/
.ps-sidebar-container{
  background-color: white !important;
}

li{
  font-family: "Manrope" !important;
}

.react-datepicker__day--selected{
  background-color: #ff2626 !important;
}

.react-datepicker__day{
  font-family: "Manrope" !important;
  font-size: 12px;
}

.react-datepicker__day--keyboard-selected{
  background-color: #ffb5b5;
}

.react-datepicker__day--keyboard-selected:hover{
  background-color: #ff2626;
}

.react-datepicker__header{
  background-color: white !important;
  border-bottom: none !important
}

.react-datepicker__day-names{
  /* border-top: ; */
  border-top: 1px solid #EEEEEE !important;
}

.react-datepicker__month-container{
  padding: 10px;
}

.dateMeetingContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  div{
    div{
      .react-datepicker__month-container{
        width: 334px;
      }
    }
  }
}

.react-datepicker__day-names, .react-datepicker__week{
  display: flex;
  justify-content: center;
  gap: 14px;
}


select{
  font-family: "Manrope" !important;
  border: none !important;
  padding: 5px;
}

.react-datepicker{
  border: 1px solid #EEEEEE;
}

.PhoneInputInput{
  border: none !important;
  width: 100% !important;
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: #808080 !important;
}

.PhoneInputInput:focus-visible{
  outline: none !important;
}

.PhoneInput{
  width: 100%;
}

.dateContainerManual{
  .react-datepicker-wrapper{
    width: 100%;
  }
}

@media screen and (max-width: 1480px) {
  .dateMeetingContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  
    div{
      div{
        .react-datepicker__month-container{
          width: max-content;
        }
      }
    }
  }

  .react-datepicker__day-names, .react-datepicker__week{
   
    gap: 0px;
  }

  .dateContainerManual{
    .react-datepicker-wrapper{
      width: 100%;
    }
  }
  
}