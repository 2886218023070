@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.label {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.inputLabel {
  display: flex;
  align-items: center;
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  svg {
    margin-left: 4px;
  }
}

.crossIcon {
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.tooptip {
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.selectionContainer {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: $BLACK;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px !important;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "height": 36,
      "margin-top": 10,
    )
  );
}

.counterContainer {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: $BLACK;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px !important;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    border: none !important;
    font-weight: 500;
    font-family: "Manrope";
    width: 18px;

    @include responsiveProperty(
      (
        "font-size": 14,
        "line-height": 22,
      )
    );
  }

  input:focus-visible {
    outline: none !important;
  }

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "height": 36,
      "margin-top": 10,
    )
  );
}

.counterArrows {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.dayLabel {
  border: 1px solid $LIGHT_GRAY;
  border-bottom: none;
  padding: 18px 19px;
  display: flex;
  justify-content: center;

  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.firstDayLabel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lastDayLabel {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid $LIGHT_GRAY !important;
}

.setTimeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
}

.setTime {
  border: 1px solid $LIGHT_GRAY;
  display: flex;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  position: relative;

  @include responsiveProperty(
    (
      "width": 163,
      "height": 36,
    )
  );
}

.removeIcon {
  position: absolute;
  top: -10px;
  cursor: pointer;
}

.setTimeOpen {
  background: $LIGHT_GRAY2;
}

.borderStartTime {
  border-bottom: 1px solid $RED;
}

.time {
  font-weight: 500;
  font-family: "Manrope";
  color: $BLUE;
  height: 100%;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.btnTime {
  border: 1px solid transparent !important;
  background-color: transparent;
  cursor: pointer;
}

.btnTimeOpen {
  border: 1px solid $LIGHT_GRAY !important;
  background-color: $LIGHT_GRAY2;
  cursor: pointer;
}

.menuItems {
  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: #d9d9d9 !important;
  }

  &::-webkit-scrollbar {
    width: 20px !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: #d9d9d9 !important;
  }

  scrollbar-color: #d9d9d9 transparent !important;
  scrollbar-width: thin !important;
}

.menuItem {
  padding: 8px 10px;
  width: 134px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .crossIcon {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .tooptip {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .selectionContainer {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .counterContainer {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;

    .input {
      font-size: calculatePixels(1200, 14) !important;
      line-height: calculatePixels(1200, 22) !important;
    }
  }
  .dayLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .time {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .setTime {
    width: calculatePixels(1200, 163) !important;
    height: calculatePixels(1200, 36) !important;
  }
}
