@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.inputLabel {
  display: flex;
  align-items: center;
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  svg {
    margin-left: 4px;
  }
}

.selectionContainer {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: $BLACK;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px !important;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "height": 36,
      "margin-top": 10,
    )
  );
}

.gridEnd {
  display: flex !important;
  justify-content: end !important;
  flex-direction: column !important;
}

.phoneNumber {
  border: 1px solid $LIGHT_GRAY;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 8px;

  div {
    input {
      width: 100% !important;
      box-sizing: border-box !important;
      font-weight: 500 !important;
      font-family: "Manrope" !important;
      color: $BLACK !important;
      font-size: 14px !important;
    }
  }
}

.errorBorder {
  border: 1px solid $RED !important;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.backBtn,
.backBtn:hover {
  padding: 7px 56px;
  width: fit-content;
  color: $GRAY;
  background-color: $LIGHT_GRAY;
  border: 1px solid $LIGHT_GRAY2;
}

.meetBtn,
.meetBtn:hover {
  padding: 7px 15px !important;
  width: fit-content !important;
  border: 1px solid $RED !important;
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.description {
  width: 100%;
  border-radius: 6px;
  border: 1px solid $LIGHT_GRAY;
  padding: 10px;
  box-sizing: border-box;
  resize: none;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "height": 97,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.description:focus-visible {
  outline: none !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .inputLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .selectionContainer {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .description {
    height: calculatePixels(1200, 97) !important;
    margin-top: calculatePixels(1200, 10) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 800px) {
  .btnContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .backBtn {
    width: 100%;
  }

  .meetBtn {
    width: 100% !important;
  }
}
