@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  min-height: 100%;
  max-width: auto !important;
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.highlighted {
  color: $BLACK !important;
}

.markedAllLabel {
  display: flex;
  align-self: center;
  color: $GRAY;
  width: 100%;
  justify-content: end;
  cursor: pointer;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  svg {
    margin-right: 5px;
    @include responsiveProperty(
      (
        "width": 16,
        "height": 16,
      )
    );
  }
}

.label {
  color: $GRAY;
  width: 100%;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.highlight {
  color: $BLACK;
}

.labelTab {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  text-transform: none !important;
  padding: 0px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.active {
  color: $BLACK !important;
}

.notificationContainer {
  // display: flex;
  // justify-content: center;
  margin: 0 auto;
  @include responsiveProperty(
    (
      "max-width": 683,
    )
  );
}

.img {
  margin-right: 20px;
  @include responsiveProperty(
    (
      "width": 46,
      "height": 46,
    )
  );
}

.singleContainer {
  display: flex;
  align-items: center;
  @include responsiveProperty(
    (
      "padding": 16,
    )
  );
}

.mt {
  margin-top: 6px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer{
    padding: calculatePixels(1200, 24) !important;

  }
  .markedAllLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;

    svg {
      width: calculatePixels(1200, 16) !important;
      height: calculatePixels(1200, 16) !important;
    }
  }

  .notificationContainer {
    max-width: calculatePixels(1200, 683) !important;
  }

  .label {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .labelTab {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .img {
    width: calculatePixels(1200, 46) !important;
    height: calculatePixels(1200, 46) !important;
  }

  .singleContainer {
    padding: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 992px) {
  .notificationContainer {
    max-width: 100% !important;
  }

  .mainContainer{
    padding: 18px !important;
  }
}
