@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.background {
  background-color: $BACKGROUND !important;
  height: 100vh;
  padding: 30px 80px;
  box-sizing: border-box;

  @include responsiveProperty(
    (
      "padding-top": 30,
      "padding-bottom": 30,
      "padding-right": 80,
      "padding-left": 80,
    )
  );
}

.logo {
  @include responsiveProperty(
    (
      "width": 176.85,
      "height": 27.62,
    )
  );
}

.authContainer {
  background-color: white;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "width": 528,
      "padding": 24,
    )
  );
}

.heading {
  margin: 0px;
  font-family: "Manrope";
  font-weight: 400;

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
    )
  );
}

.inputContainer {
  display: flex;
  flex-direction: column;
  @include responsiveProperty(
    (
      "margin-top": 16,
      "gap": 16,
    )
  );
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include responsiveProperty(
    (
      "margin-top": 14,
    )
  );
}

.rememberMeSection {
  display: flex;
}

.actionLabel {
  font-weight: 500;
  font-family: "Manrope";

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.loginLabel {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  padding-left: 10px;
  padding-right: 10px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "width": 84,
    )
  );
}

.loginBtn {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.lines {
  width: 35%;
  height: 1.5px;
  background-color: $LIGHT_GRAY;
}

.linesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsiveProperty(
    (
      "margin-top": 19,
      "margin-bottom": 19,
    )
  );
}

.googleBtn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $LIGHT_GRAY !important;
}

.googleIcon {
  @include responsiveProperty(
    (
      "width": 22,
      "height": 22,
    )
  );
}

.accountCreateLabel {
  font-weight: 500;
  font-family: "Manrope";
  text-align: center;
  width: 100% !important;
  display: flex;
  justify-content: center;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "margin-top": 16,
      "margin-bottom": 16,
    )
  );

  span {
    color: $RED;
    margin-left: 5px;
    cursor: pointer;
  }
}

.introBtn {
  background-color: $LIGHT_YELLOW !important;
  color: $BROWN !important;
  border: 1px solid $LIGHT_GRAY;
}

.welcomeLabelContainer {
  @include responsiveProperty(
    (
      "margin-top": 65,
    )
  );
}

.close,
.close:hover {
  padding: 7px 130px;
  color: $GRAY;
  background-color: $LIGHT_GRAY;
  border: 1px solid $LIGHT_GRAY2;
}

.welcomeLabel {
  font-weight: 500;
  font-family: "Manrope";
  width: 100%;

  @include responsiveProperty(
    (
      "font-size": 32,
      "line-height": 35.2,
    )
  );
}

.welcomeDescription {
  font-weight: 400;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 22,
      "padding-top": 10,
    )
  );
}

.authBanner {
  @include responsiveProperty(
    (
      "width": 574,
      "height": 379.15,
    )
  );
}

.outerMostGrid {
  height: 90vh;
}

@media screen and (max-width: 900px) {
  .bannerContainer,
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .outerMostGrid {
    height: 100vh !important;
  }
  .welcomeGrid {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 767px) {
  .loginLabel {
    width: 91px !important;
  }

  .authBanner {
    display: none !important;
  }

  .outerMostGrid {
    display: block !important;
  }

  .authContainer {
    width: 100% !important;
  }

  .background {
    padding: 20px !important;
  }

  .welcomeGrid {
    align-items: start !important;
    justify-content: start !important;
  }
}

@media screen and (min-height: 1300px) {
  .welcomeGrid {
    display: flex !important;
    align-items: center !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }
  .actionLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .welcomeDescription {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 22) !important;
    padding-top: calculatePixels(1200, 10) !important;
  }

  .welcomeLabel {
    font-size: calculatePixels(1200, 32) !important;
    line-height: calculatePixels(1200, 35.2) !important;
  }

  .accountCreateLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    margin-top: calculatePixels(1200, 16) !important;
    margin-bottom: calculatePixels(1200, 16) !important;
  }
}
