@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  overflow: hidden;

  @include responsiveProperty(
    (
      "height": 176,
      "padding": 24,
    )
  );
}

.icon {
  position: absolute;
  right: -20px;
  bottom: -20px;

  @include responsiveProperty(
    (
      "height": 123,
      "width": 126.39,
    )
  );
}

.avatar {
  border-radius: 8px !important;

  @include responsiveProperty(
    (
      "height": 70,
      "width": 71,
    )
  );
}

.heading {
  font-weight: 600;
  font-family: "Manrope";

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 20,
    )
  );
}

.profileContainer {
  display: flex;

  @include responsiveProperty(
    (
      "padding-bottom": 23,
    )
  );
}

.headingContainer {
  display: flex;
  flex-direction: column;

  @include responsiveProperty(
    (
      "margin-left": 12,
      "gap": 10,
    )
  );
}

.dateHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.dateContainer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;

  @include responsiveProperty(
    (
      "gap": 6,
      // "padding-left": 24,
      "padding-bottom": 24,
    )
  );
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 8s linear infinite;
}


@media screen and (max-width: 500px) {
  .icon {
    height: calculatePixels(900, 123) !important;
    width: calculatePixels(900, 127) !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }

  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 20) !important;
  }

  .dateHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}
