@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.accordian {
  box-shadow: none !important;
  div {
    padding: 0px !important;
  }
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  cursor: pointer;
  @include responsiveProperty(
    (
      "margin-left": 24,
    )
  );
}

.heading {
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.accordingSummary {
  div {
    align-items: center !important;
  }
}

.tableRow {
  td {
    border: none !important;
  }
}

.table {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px !important;
  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #ff2626;
  }

  scrollbar-color: #ff2626 transparent !important;
  scrollbar-width: thin !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;


  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  font-weight: 400;
  color: $BLACK2;
  max-width: 120px !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

.labelTag {
  font-weight: 500;
  color: $GRAY;
  padding: 7px 10px 7px 10px !important;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tableHead {
    th {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
    }
  }
  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1200, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
      "font-size": 12,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
      "font-size": 12,

    )
  );
}

.completedLabel{
  font-weight: 600 !important;
  color: $GRAY !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media screen and (max-width: 450px) {
  .tagContainer {
    margin-left: calculatePixels(1150, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1150, 12) !important;
    line-height: calculatePixels(1150, 16) !important;
  }
}

@media screen and (max-width: 990px) {
  .tableCell {
    @include responsiveProperty(
      (
        "min-width": 130,
      )
    );
  }
}
