@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.profile_pic {
  border-radius: 50%;
  @include responsiveProperty(
    (
      "width": 30,
      "height": 30,
    )
  );
}

.memberName {
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.memberEmail {
  color: $GRAY;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.outerContainer {
  display: flex;
  justify-content: space-between;

  @include responsiveProperty(
    (
      "padding": 22,
    )
  );
}

.borderBottom {
  border-bottom: 1px solid $LIGHT_GRAY;
}

.icon {
  @include responsiveProperty(
    (
      "margin-left": 15,
    )
  );
}

.btn {
  color: $GRAY !important;
  background-color: white !important;
  font-weight: 400;
  border: 1.5px solid $LIGHT_GRAY;
  box-sizing: border-box;
  width: fit-content;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding": 15,
    )
  );
}

.btnSelected {
  background-color: $LIGHT_BLUE2 !important;
  border: 1px solid transparent;
  color: white !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .icon {
    margin-left: calculatePixels(1200, 14) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    padding: calculatePixels(1200, 22) !important;
  }
  .profile_pic {
    width: calculatePixels(1200, 30) !important;
    height: calculatePixels(1200, 30) !important;
  }
  .outerContainer {
    padding: calculatePixels(1200, 22) !important;
  }
  .memberName {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .memberEmail {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 750px) {
  .outerContainer{
    display: block !important;
  }
  .btn{
    margin-top: 15px !important;
  }
}

@media screen and (max-width: 450px) {
 
  .btn{
    width: 100% !important;
  }
}
