$BACKGROUND: #fafafa;
$GRAY: #808080;
$LIGHT_GRAY: #eeeeee;
$LIGHT_GRAY2: #f8f8f8;
$LIGHT_GRAY3: #e9eaec;
$LIGHT_GRAY4: #98A9BC;
$DARK_GRAY: #3d3d3d;
$RED: #ff2626;
$RED_2: #5d1715;
$RED_3: #E16F64;
$LIGHT_RED: #ffb5b5;
$BLACK: #2e2e2e;
$BLACK2: #262626;
$GREEN: #27a376;
$GREEN_2: #6c9b7d;
$GREEN_3: #1c3829;
$LIGHT_GREEN: #dbeddb;
$LIGHT_BLUE: #d3e5ef;
$BLUE: #183347;
$LIGHT_YELLOW: #fdecc8;
$SELECT_GRAY: #f3f3f3;
$BROWN: #402c1b;
$LIGHT_RED: #ffe2dd;
$LIGHT_PURPLE: #e8deee;
$PURPLE: #412454;
$LIGHT_BLUE2: #5B97BD
