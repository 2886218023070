@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.btn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;
  padding: 7px 16px;
  font-weight: 600;

  svg {
    path {
      stroke: $BLACK;
    }
  }

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  @include responsiveProperty(
    (
      "padding-right": 16,
    )
  );
}

.heading {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-family: "Manrope";
  font-weight: 500;
  color: $GRAY;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.serviceContainer {
  display: flex;
  gap: 24px;
}

.singleServiceContainer {
  border: 1px solid $LIGHT_GRAY;
  background-color: $LIGHT_GRAY2;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  min-height: 510px;
  overflow: hidden;
}

.btn {
  background-color: transparent !important;

  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.serviceName {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
    )
  );
}

.serviceDescription {
  font-family: "Manrope";
  font-weight: 300;
  color: $BLACK2;
  width: 100% !important;
  min-height: 60px;
  max-width: 300px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 19,
    )
  );
}

.serviceHeadingContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.serviceSubHeadingContainer {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 14px;
}

.serviceContainer {
  margin-top: 24px;
}

.topServiceContainer {
  display: flex;
  justify-content: space-between;
}

.serviceIcon {
  @include responsiveProperty(
    (
      "width": 40,
      "height": 40,
    )
  );
}

.serviceAmount {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 24,
      "line-height": 36,
    )
  );

  label {
    font-family: "Manrope";
    font-weight: 500;
    color: $GRAY;

    @include responsiveProperty(
      (
        "font-size": 12,
        "line-height": 16,
        "margin-left": 3,
      )
    );
  }
}

.serviceSubHeading {
  font-family: "Manrope";
  font-weight: 400;
  color: $BLACK2;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.pointContainer {
  margin-top: 24px;
}

.singleServicePoint {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 16,
    )
  );
}

.singleInfoContainer {
  overflow: hidden;
  position: relative;
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.contactInfo {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.infoGrid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contactBtn {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2 !important;
  background-color: transparent !important;
  width: fit-content !important;
  border: 1px solid $BLACK;
  padding: 7px 16px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.icon {
  position: absolute;
  right: -20px;
  bottom: -20px;

  @include responsiveProperty(
    (
      "height": 123,
      "width": 126.39,
    )
  );
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 8s linear infinite;
}

@media screen and (max-width: 500px) {
  .icon {
    height: calculatePixels(900, 123) !important;
    width: calculatePixels(900, 127) !important;
  }
}

@media screen and (max-width: 750px) {
  .serviceContainer {
    display: block;
  }
  .headingContainer {
    display: block;
  }
  .btnEdit {
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer,
  .singleInfoContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .btn {
    height: calculatePixels(1200, 36) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .label {
    padding-right: calculatePixels(1200, 16) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .serviceName {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }
  .serviceDescription {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 19) !important;
  }
  .serviceIcon {
    height: calculatePixels(1200, 40) !important;
    width: calculatePixels(1200, 40) !important;
  }
  .serviceAmount {
    font-size: calculatePixels(1200, 24) !important;
    line-height: calculatePixels(1200, 36) !important;

    label {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
      margin-left: calculatePixels(1200, 3) !important;
    }
  }
  .serviceSubHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .singleServicePoint {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
