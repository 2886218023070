@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  min-height: 90%;
}

.singleSettingOption {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  label {
    font-weight: 500;
    color: $DARK_GRAY;
    margin-left: 10px;
    cursor: pointer;
    font-family: "Manrope" !important;

    @include responsiveProperty(
      (
        "font-size": 12,
        "line-height": 19,
      )
    );
  }
}

.navigationItems {
  background-color: white !important;
  border: 1px solid $LIGHT_GRAY3;
  padding: 24px;
  border-radius: 8px;
}

.active {
  background-color: $LIGHT_GRAY2;
  border-radius: 10px;
}

.settingsContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid #e9eaec;
  padding: 24px !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .singleSettingOption {
    label {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 19) !important;
    }
  }
}
