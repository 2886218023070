@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.upperMostContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px !important;
}

.tableRow {
  td {
    border: none !important;
  }
}

.table {
  min-width: none !important;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  font-weight: 400;
  color: $BLACK2;
  max-width: 120px !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

.btn {
  background-color: white !important;
  color: $GRAY !important;
  border: 1px solid $LIGHT_GRAY;
  font-weight: 500;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "height": 36,
      "width": 170,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.icon {
  margin-left: 5px !important;
  path {
    stroke: $GRAY;
  }
}

.profile_pic {
  @include responsiveProperty(
    (
      "height": 24,
      "width": 24,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "font-size": 12,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "font-size": 12,
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

.btnContainer {
  display: flex;

  @include responsiveProperty(
    (
      "gap": 15,
      "margin-top": 20,
      "margin-bottom": 20,
    )
  );
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  @include responsiveProperty(
    (
      "margin-left": 24,
    )
  );
}

.labelTag {
  font-weight: 500;
  color: $GRAY;
  padding: 7px 10px 7px 10px !important;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.projectTitle {
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.upperContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $LIGHT_GRAY;
  @include responsiveProperty(
    (
      "padding": 16,
    )
  );
}

.newDotContainer {
  background-color: white;
  padding: 1px;
  position: absolute;
  top: 3px;
  right: 0px;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
}

.newDot {
  background-color: #ff2626;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tableHead {
    th {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
    }
  }
  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 195) !important;
    height: calculatePixels(1200, 36) !important;
  }
  .profile_pic {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1200, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .projectTitle {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .upperContainer {
    padding: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 650px) {
  .tagContainer {
    margin-left: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 600px) {
  .tagContainer {
    margin-left: 0px !important;
    margin-top: 10px !important;
    margin-right: 5px;
  }
  .newDotContainer {
    top: 20px !important;
  }
  .btnContainer {
    display: block;
  }
  .btn {
    margin-bottom: 20px;
    width: 100% !important;
  }
  .topActionContainer{
    display: block !important;
  }
}

@media screen and (min-width: 900px) {
  .paddingLeft {
    @include responsiveProperty(
      (
        "padding-left": 10,
      )
    );
  }

  .paddingRight {
    @include responsiveProperty(
      (
        "padding-right": 10,
      )
    );
  }
}
