@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.label {
  font-weight: 600;
  font-family: "Manrope";
  color: $BLACK2;
  font-size: 16px;
  line-height: 26px;

  @include responsiveProperty(
    (
      "padding-top": 8,
    )
  );
}

.subLabel {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 6px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  gap: 20px;
}

.btnConfirm {
  padding: 7px 20px;
}

.BiggerScreenLabel {
  @include responsiveProperty(
    (
      "padding-top": 8,
      "font-size": 16,
      "line-height": 26,
    )
  );
}

.BiggerScreenSubLabel {
  @include responsiveProperty(
    (
      "padding-top": 6,
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.icon {
  @include responsiveProperty(
    (
      "width": 46,
      "height": 46
    )
  );
}
