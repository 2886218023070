@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  min-height: 90%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingContainer2 {
  display: flex;
  align-items: center;
}

.heading {
  font-weight: 500;
  margin-bottom: 4px !important;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.projectsContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

@media screen and (max-width: 650px) {
  .headingContainer {
    display: block !important;
  }

  .headingContainer2 {
    justify-content: space-between;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 20) !important;
  }
}

@media screen and (max-width: 450px) {
  .mainContainer {
    padding: calculatePixels(1150, 24) !important;
  }

  .heading {
    font-size: calculatePixels(1150, 20) !important;
    line-height: calculatePixels(1150, 34) !important;
  }

  .subHeading {
    font-size: calculatePixels(1150, 14) !important;
    line-height: calculatePixels(1150, 20) !important;
  }
}
