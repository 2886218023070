@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  @include responsiveProperty(
    (
      "margin-bottom": 24,
    )
  );
}

.icon {
  margin-right: 12px;

  @include responsiveProperty(
    (
      "width": 15,
      "height": 15,
    )
  );
}

.icon2 {
  margin-right: 5px;

  @include responsiveProperty(
    (
      "width": 20,
      "height": 20,
    )
  );
}

.label {
  cursor: pointer;
  font-weight: 500;
  color: $BLACK;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .container {
    margin-bottom: calculatePixels(1200, 24) !important;
  }
}
