@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.label {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.crossIcon {
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.inputLabel {
  display: flex;
  align-items: center;
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  svg {
    margin-left: 4px;
  }
}

.selectionContainer {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: $BLACK;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px !important;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "height": 36,
      "margin-top": 10,
    )
  );
}

.description {
  width: 100%;
  border-radius: 6px;
  border: 1px solid $LIGHT_GRAY;
  padding: 10px;
  box-sizing: border-box;
  resize: none;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  background-color: $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "height": 97,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.description:focus-visible {
  outline: none !important;
}

.btnContainer {
  display: flex;
  justify-content: end;
  margin-top: 24px;
  gap: 10px;
}

.send {
  padding: 7px 25px;
}

.cancel,
.cancel:hover {
  padding: 7px 25px;
  color: $GRAY;
  border: 1px solid $LIGHT_GRAY2;
  background-color: $LIGHT_GRAY;
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.errorBorder {
  border: 1px solid $RED !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .crossIcon {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .inputLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .selectionContainer {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .description {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 97) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .error {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
