@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.outerMostContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.mainContainer {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "width": 528,
      "margin-top": 25,
    )
  );
}

.logo {
  @include responsiveProperty(
    (
      "width": 176.85,
      "height": 27.62,
    )
  );
}

.heading {
  margin: 0px;
  font-family: "Manrope";
  font-weight: 400;
  text-align: center;

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
      "margin-top": 5,
    )
  );
}

.subHeading {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  text-align: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 25,
      "margin-top": 5,
    )
  );
}

.btn {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.backBtn {
  background-color: $LIGHT_GRAY !important;
  color: $GRAY !important;
  border: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "margin-top": 10,
    )
  );
}


.footer{
  position: fixed !important;
  padding: 40px;
}

.circledTick{
  @include responsiveProperty(
    (
      "width": 56,
      "height": 56,
    )
  );
}

@media screen and (max-width: 500px) {
  .mainContainer{
    width: 80% !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footerCopyright {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .footerLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }
  
  .subHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    margin-bottom: calculatePixels(1200, 25) !important;
    margin-top: calculatePixels(1200, 5) !important;
  }
}