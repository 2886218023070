@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.btn {
  color: $GRAY !important;
  background-color: white !important;
  font-weight: 400;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

.red {
  color: $RED;
  cursor: pointer;
}

.black {
  color: $BLACK;
  cursor: pointer;
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

@media screen and (max-width: 600px) {
  .cancel {
    padding: 20px !important;
  }
  .confirm {
    padding: 20px !important;
  }
}
