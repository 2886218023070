@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.profile_pic {
  position: relative;
  margin-left: -2px;
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .profile_pic {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
}
