@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  display: grid;
  height: 87%;
  grid-template-rows: 1fr;
}

.header {
  border-bottom: 1px solid $LIGHT_GRAY;
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.name {
  font-weight: 600 !important;
  color: $BLACK2 !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.lastSeen {
  font-weight: 400 !important;
  color: $GRAY !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding-top": 6,
    )
  );
}

.sendContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;

  @include responsiveProperty(
    (
      "height": 50,
    )
  );
}

.sendInput {
  width: 89%;
  height: 100%;
  box-sizing: border-box;
  background-color: $LIGHT_GRAY2;
  border: none !important;
  font-weight: 400;
  font-family: "Manrope" !important;
  resize: none;
  @include responsiveProperty(
    (
      "padding": 14,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.sendInput:focus-visible {
  outline: none !important;
}

.mainSendContainer {
  position: absolute;
  width: 100%;

  @include responsiveProperty(
    (
      "bottom": 16,
    )
  );
}

.sendIconBtn {
  border: none !important;
  background: $RED;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include responsiveProperty(
    (
      "height": 32,
      "width": 32,
      "margin-left": 7,
    )
  );
}

.requestsCard {
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.dateStamp {
  display: flex;
  color: $GRAY;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 30,
    )
  );

  label {
    @include responsiveProperty(
      (
        "width": 220,
      )
    );
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: $LIGHT_GRAY;
}

.innerContainer {
  display: flex !important;
  justify-content: space-between !important;

  @include responsiveProperty(
    (
      "margin-top": 24,
      "margin-bottom": 24,
    )
  );
}

.profile_pic {
  @include responsiveProperty(
    (
      "height": 46,
      "width": 46,
      "margin-right": 15,
    )
  );
}

.contentContainer {
  @include responsiveProperty(
    (
      "width": 268,
    )
  );
}

.timeStamp {
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.message {
  color: $DARK_GRAY;
  font-weight: 400;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "margin-top": 6,
    )
  );
}

.messagesContainer {
  overflow: scroll;
  padding: 0px 24px;
  margin-top: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #d9d9d9;
  }

  scrollbar-color: #d9d9d9 transparent !important;
  scrollbar-width: thin !important;
}

.file {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  min-width: 202px;

  @include responsiveProperty(
    (
      "height": 68,
      "margin-top": 15,
    )
  );
}

.fileIconContainer {
  background-color: white;
  width: fit-content;
  padding: 10px;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
}

.fileInnerContainer {
  display: flex;
  width: 85%;
  @include responsiveProperty(
    (
      "padding": 12,
    )
  );
}

.fileName {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "width": 115,
    )
  );
}

.size {
  color: $GRAY;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.fileOptionsBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .header {
    padding: calculatePixels(1200, 24) !important;
  }
  .name {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .lastSeen {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    padding-top: calculatePixels(1200, 6) !important;
  }
  .sendContainer {
    height: calculatePixels(1200, 50) !important;
  }
  .sendInput {
    padding: calculatePixels(1200, 14) !important;
  }
  .mainSendContainer {
    bottom: calculatePixels(1200, 16) !important;
  }
  .sendIconBtn {
    height: calculatePixels(1200, 32) !important;
    width: calculatePixels(1200, 32) !important;
    margin-left: calculatePixels(1200, 7) !important;
  }
  .requestsCard {
    padding: calculatePixels(1200, 24) !important;
  }
  .dateStamp {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    margin-bottom: calculatePixels(1200, 30) !important;

    label {
      width: calculatePixels(1200, 220) !important;
    }
  }
  .profile_pic {
    height: calculatePixels(1200, 46) !important;
    width: calculatePixels(1200, 46) !important;
    margin-right: calculatePixels(1200, 15) !important;
  }
  .contentContainer {
    width: calculatePixels(1200, 268) !important;
  }
  .timeStamp {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .message {
    margin-top: calculatePixels(1200, 6) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .innerContainer {
    margin-top: calculatePixels(1200, 24) !important;
    margin-bottom: calculatePixels(1200, 24) !important;
  }
  .file {
    height: calculatePixels(1200, 68) !important;
    margin-top: calculatePixels(1200, 15) !important;
  }
  .fileInnerContainer {
    padding: calculatePixels(1200, 12) !important;
  }
  .fileName {
    width: calculatePixels(1200, 115) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .size {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
