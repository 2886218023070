@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.label {
  color: $BLACK;
  font-weight: 500;
  margin-top: 10px;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.description {
  color: $GRAY;
  font-weight: 600;
  margin-top: 10px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }

  .description {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
