@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.notificationBtnContainer {
  display: flex;
  align-items: center;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  margin-right: 14px;
}

.notificationBtn {
  background-color: white;
  border: 2px solid $LIGHT_GRAY;
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
}

.notificationTabActive{
  background-color: $LIGHT_GRAY !important;

}

.notificationIconStyle {
  fill: $GRAY !important;
}

.dropdownUserinfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px;
}

.name {
  font-weight: 500;
  font-family: "Manrope";
  color: $BLACK !important;
  font-size: 16px;
  line-height: 23px;
}

.introContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 8px;
}

.role {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY !important;
  font-size: 12px;
  line-height: 16px;
}

.topLevelContainer {
  max-width: 100% !important;
  margin: 0;
  height: 100%;
  display: flex !important;
}

.headerMainContainer {
  background: white !important;
  box-shadow: -16px -20px 4px -1px rgba(0, 0, 0, 0.2),
    -4px -2px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "height": 80,
    )
  );
}

.buttonInfo {
  border-radius: 5px !important;
  padding: 3px !important;
}

.headerItems {
  display: flex !important;
  justify-content: end !important;
}

.redDot {
  background-color: $RED;
  height: 6px;
  width: 6px;

  border-radius: 50%;
}

.alert {
  background-color: white;
  padding: 1px;
  position: absolute;
  top: 6px;
  right: 7px;
  border-radius: 50%;
}

.searchContainer {
  background-color: $LIGHT_GRAY2;
  width: 220px;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px;
  display: flex;
  align-items: center;

  input {
    border: none !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-weight: 500;
    font-family: "Manrope";
    color: $GRAY;
    font-size: 14px;
    line-height: 22px;
    border-radius: 6px;
    background-color: $LIGHT_GRAY2;
  }

  input:focus-visible {
    outline: none !important;
  }

  svg {
    padding: 0px 10px;
  }
}

.settingsContainer {
  div {
    ul {
      padding: 14px !important;

      li {
        padding: 9px 12px !important;
        width: 174px !important;
      }
    }
  }
}

.settingsLabel {
  font-weight: 500;
  font-family: "Manrope";
  color: $DARK_GRAY;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px !important;
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

@media screen and (max-width: 1440px) {
  .headerMainContainer {
    height: 80px !important;
  }
}

@media screen and (max-width: 410px) {
  .confirm {
    padding-right: calculatePixels(768, 35) !important;
    padding-left: calculatePixels(768, 35) !important;
  }
}


