@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.label {
  color: $DARK_GRAY;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.crossIcon {
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.addBtn {
  cursor: pointer;
  border: 1px solid $LIGHT_GRAY;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "width": 36,
      "height": 36,
      "margin-left": 14,
    )
  );
}

.photoContainer {
  display: flex;
  align-items: center;
}

.profile_picture {
  border-radius: 50%;
  object-fit: cover;
  @include responsiveProperty(
    (
      "width": 88,
      "height": 88,
    )
  );
}

.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 2px solid $LIGHT_GRAY;
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

.cancelEdit {
  background-color: white !important;
  border: 1px solid $LIGHT_GRAY;
  padding: 20px !important;
  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.saveEdit {
  padding: 20px !important;

  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.buttonContainer {
  display: flex;
  justify-content: end;
  gap: 10px;

  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.gridContainer {
  @include responsiveProperty(
    (
      "margin-top": 35,
    )
  );
}

.upperRightGrid {
  display: flex;
  align-items: end;
}

.rightGrid {
  @include responsiveProperty(
    (
      "margin-top": 24,
      "padding-left": 12,
    )
  );
}

.leftGrid {
  @include responsiveProperty(
    (
      "margin-top": 24,
      "padding-right": 12,
    )
  );
}

.inputLabel {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  span {
    color: $RED;
  }
}


.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.errorBorder{
  border: 1px solid $RED !important;
}

.phoneInput {
  width: 100%;
  box-sizing: border-box;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  display: flex;
  align-items: center;

  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  padding-left: 22px;

  @include responsiveProperty(
    (
      "height": 36,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media screen and (max-width:935px){
  .rightGrid{
    padding-left: 0px !important;
  }
  .leftGrid{
    padding-right: 0px !important;

  }
}
