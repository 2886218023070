@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.rightGrid {
  border-right: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "padding-top": 24,
      "padding-bottom": 24,
      "padding-right": 24,
    )
  );
}

.timeContainer {
  padding: 0px 24px 14px 24px;
  border-bottom: 1px solid $LIGHT_GRAY;
}

.timeLabel {
  font-family: "Manrope" !important;
  font-weight: 500;
  border: none;
  background-color: transparent;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.timeLabel:hover {
  background-color: $LIGHT_GRAY;
}

.membersContainer {
  padding: 14px 24px 14px 24px;
  border-bottom: 1px solid $LIGHT_GRAY;
}

.statusContainer {
  padding: 14px 24px 14px 24px;
  border-bottom: 1px solid $LIGHT_GRAY;
}

.paddingBorder {
  width: 1px;
  height: 30px;
  background-color: $LIGHT_GRAY;
}
.membersContainer {
  width: 100%;
  box-sizing: border-box;
}

.membersBtn {
  width: 100%;
  display: flex;
  padding: 14px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.membersBtn:hover {
  background-color: $LIGHT_GRAY;
}

.memberLabel {
  font-family: "Manrope" !important;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .rightGrid {
    padding-top: calculatePixels(1200, 24) !important;
    padding-bottom: calculatePixels(1200, 24) !important;
    padding-right: calculatePixels(1200, 24) !important;
  }
  .timeLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .memberLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}



//////
/// 
/// 

.membersContainer1 {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
  height: 350px;
  overflow: scroll;
  @include responsiveProperty(
    (
      "margin-top": 14,
    )
  );

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #ff2626;
  }

  scrollbar-color: #ff2626 transparent !important;
  scrollbar-width: thin !important;
}

.inviteContainer {
  width: 86% !important;
  border-radius: 8px;
  background-color: $LIGHT_GRAY2;
  @include responsiveProperty(
    (
      "padding": 14,
    )
  );
}

.inviteTitle {
  font-weight: 600;
  color: $BLACK;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.inviteSubTitle {
  font-weight: 500;
  color: $GRAY;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-top": 6,
    )
  );
}

.btn {
  background-color: $GREEN_2;
  width: 140px;

  @include responsiveProperty(
    (
      "padding-top": 4,
      "padding-bottom": 4,
      "padding-right": 10,
      "padding-left": 10,
      "margin-top": 14,
    )
  );
}

.btn:hover {
  background-color: $GREEN_2;
}

.labelTab {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  text-transform: none !important;
  padding: 15px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  width: fit-content !important;
  min-width: fit-content !important;
  max-width: fit-content !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.active {
  color: $BLACK !important;
}

.searchContainer {
  display: flex;
  align-items: center;
}

.searchInput {
  border: none !important;
  font-weight: 500;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 22,
    )
  );
}

.searchInput:focus-visible {
  outline: none !important;
}

.icon {
  margin-right: 10px;
}

.topActionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include responsiveProperty(
    (
      "margin-top": 14,
    )
  );
}

.profile_pic {
  border-radius: 50%;
  @include responsiveProperty(
    (
      "width": 30,
      "height": 30,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .inviteContainer {
    padding: calculatePixels(1200, 14) !important;
  }
  .inviteTitle {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .labelTab {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .topActionContainer {
    margin-top: calculatePixels(1200, 14) !important;
  }
  .profile_pic {
    width: calculatePixels(1200, 30) !important;
    height: calculatePixels(1200, 30) !important;
  }
  .membersContainer {
    margin-top: calculatePixels(1200, 16) !important;
  }
  .inviteSubTitle {
    margin-top: calculatePixels(1200, 6) !important;
  }
  .searchInput {
    margin-top: calculatePixels(1200, 14) !important;
  }
  .btn {
    margin-top: calculatePixels(1200, 14) !important;
  }
}

@media screen and (max-width: 900px) {
  .inviteContainer {
    width: 100% !important;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .btn {
    width: 100%;
  }
  .topActionContainer{
    display: block;
  }
  .searchContainer{
    width: 100% !important;
    margin-top: 15px !important;
    border: 1px solid $LIGHT_GRAY;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
  }

}
