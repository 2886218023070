@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  min-height: 90%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.btn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "height": 36,
      "width": 163,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  @include responsiveProperty(
    (
      "padding-right": 16,
    )
  );
}

.heading {
  font-weight: 500;
  margin-bottom: 4px !important;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingContainer2 {
  display: flex;
  align-items: center;
}

.mainMessagesContainer {
  border: 1.5px solid $LIGHT_GRAY;
  height: 86%;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.grid {
  height: 100%;
}

.chatListGrid {
  border-right: 1.5px solid $LIGHT_GRAY;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.active {
  padding: 3px 10px !important;
  background-color: $LIGHT_GREEN;
  color: $GREEN_3;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.inactive {
  padding: 3px 10px !important;
  background-color: $LIGHT_RED;
  color: $BROWN;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.tableRow {
  td {
    border: none !important;
  }
}

.table {
  min-width: none !important;
  border: 1px solid $LIGHT_GRAY;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY !important;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;
  display: table-cell !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  display: table-cell !important;
  font-weight: 400;
  color: $BLACK2;
  font-family: "Manrope" !important;
  max-width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

.profile_pic {
  border-radius: 50%;
  @include responsiveProperty(
    (
      "height": 24,
      "width": 24,
    )
  );
}

.tableMainContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.resetBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $GRAY;
  cursor: pointer;
  margin-left: 15px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.active {
  padding: 3px 10px !important;
  background-color: $LIGHT_GREEN;
  color: $GREEN_3;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.deactive {
  padding: 3px 10px !important;
  background-color: $LIGHT_RED;
  color: $RED_2;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.menuItems {
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.menuItemChat {
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.menuItemDelete {
  font-weight: 400 !important;
  color: $RED !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .btn {
    height: calculatePixels(1200, 36) !important;
    width: calculatePixels(1200, 131) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .label {
    padding-right: calculatePixels(1200, 16) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .mainMessagesContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
  .actionContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
  .active {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .deactive {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .inactive {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .profile_pic {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .tableMainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
  .tableHead {
    th {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
    }
  }
  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .resetBtn {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .menuItemChat {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .menuItemDelete {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 600px) {
  .actionContainer {
    display: block !important;
  }

  .searchBarContainer {
    margin-top: 15px;
  }

  .tableCell2 {
    min-width: 150px !important;
  }
}

@media screen and (max-width: 410px) {
  .confirm {
    padding-right: calculatePixels(768, 35) !important;
    padding-left: calculatePixels(768, 35) !important;
  }
}
