@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.profileCard {
  height: max-content;
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  margin-bottom: 24px !important;
}


