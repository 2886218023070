@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:8px;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
  height: 20px;
  cursor: pointer;
}

.input {
  width: 0px;
  transition: width 0.3s;
  padding: 5px;
  border-radius: 4px;
  border: none !important;
  background-color: transparent;
  color: $GRAY;
  font-size: 16px;
}

.input.expanded {
  width: 300px;
}

.input:focus-visible {
  border: none !important;
  outline: none !important;
}

.icon {
  margin-right: -8px;
}

.iconExpanded{
  margin-right: 10px;
}


@media screen and (max-width: 600px){
  .input.expanded {
    width: 250px;
  }
}