@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;

  @include responsiveProperty(
    (
      "height": 120,
      "padding": 24,
    )
  );
}

.heading {
  font-weight: 500;
  font-family: "Manrope";

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
      "margin-bottom": 6,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.btn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;

  position: absolute;
  bottom: 0px;

  @include responsiveProperty(
    (
      "height": 36,
      "width": 195,
      "margin-bottom": 24,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  @include responsiveProperty(
    (
      "padding-right": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    height: calculatePixels(1200, 149) !important;
    padding: calculatePixels(1200, 24) !important;
  }

  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }

  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 20) !important;
  }

  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 195) !important;
    height: calculatePixels(1200, 36) !important;
  }
}


@media screen and (max-width: 900px) {
  .mainContainer{
    height: 120px !important;
  }
}