@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  margin-top: 14px;
  border-radius: 6px;
  border: 1px solid $LIGHT_GRAY;
}

.inputContainer {
  padding: 13px;

  input {
    border: none !important;
    width: 100%;
    font-weight: 600;
    font-family: "Manrope" !important;
    color: $DARK_GRAY;

    @include responsiveProperty(
      (
        "font-size": 14,
        "line-height": 22,
      )
    );
  }

  input:focus-visible {
    outline: none;
  }
}

.textareaContainer {
  padding: 13px;

  textarea {
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    font-weight: 400;
    font-family: "Manrope" !important;
    color: $GRAY;

    @include responsiveProperty(
      (
        "font-size": 14,
        "line-height": 22,
      )
    );
  }

  textarea:focus-visible {
    outline: none !important;
  }
}

.filterContainer {
  display: flex;
  gap: 10px;
  padding: 13px;
}

.footerLabel {
  font-weight: 600;
  font-family: "Manrope" !important;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.footerContainer {
  display: flex;
  padding: 13px;
  gap: 10px;
  justify-content: space-between;
  border-top: 1px solid $LIGHT_GRAY;
  align-items: center;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.addTask {
  font-weight: 600;
  font-family: "Manrope" !important;
  padding: 7px 13.5px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.cancel,
.cancel:hover {
  font-weight: 600;
  font-family: "Manrope" !important;
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  padding: 7px 19px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .inputContainer {
    input {
      font-size: calculatePixels(1200, 14) !important;
      line-height: calculatePixels(1200, 22) !important;
    }
  }
  .textareaContainer {
    textarea {
      font-size: calculatePixels(1200, 14) !important;
      line-height: calculatePixels(1200, 22) !important;
    }
  }
  .footerLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
