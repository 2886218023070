@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  min-height: 90%;
  @include responsiveProperty(
    (
      "margin-left": 25,
      "padding": 24,
    )
  );
}


.labelTab {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  text-transform: none !important;
  padding: 0px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;

  @include responsiveProperty(
    (
      "padding-left":20,
      "padding-right":20,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.active {
  color: $BLACK !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    margin-left: calculatePixels(1200, 25) !important;
  }

  .labelTab {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 940px) {
  .mainContainer {
    margin-left: 0px !important;
  }
}
