@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 412px;
}

.heading {
  font-weight: 500;
  color: $DARK_GRAY;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.label {
  font-weight: 600;
  color: $BLACK2;

  @include responsiveProperty(
    (
      "font-size": 18,
      "line-height": 26,
    )
  );
}

.description {
  font-weight: 500;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.icon {
  width: 50px;
  height: 50px;
}

.headingContainer {
  padding-bottom: 16px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.headingsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container {
  display: flex;
  gap: 10px;
  width: 420px;
  border: 1px solid $LIGHT_GRAY;
  padding: 20px 14px;
  border-radius: 8px;
  box-sizing: border-box;
}

.btn {
  font-weight: 500;
  color: $GRAY;
  border: 1px solid $LIGHT_GRAY !important;
  background-color: $LIGHT_GRAY2 !important;
  width: fit-content;
  padding: 7px 14px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .label {
    font-size: calculatePixels(1200, 18) !important;
    line-height: calculatePixels(1200, 26) !important;
  }
  .description {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
