@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.label {
  color: $DARK_GRAY;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.timeHeading {
  color: $BLACK;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.crossIcon {
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 2px solid $LIGHT_GRAY;
  margin-bottom: 24px;
}

.inputLabel {
  display: flex;
  align-items: center;
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  svg {
    margin-left: 4px;
  }

  span {
    color: $RED;
    margin-left: 5px;
  }
}

.selectionContainer {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: $BLACK;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  height: 36px !important;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "height": 36,
      "margin-top": 10,
    )
  );
}

.description {
  width: 100%;
  border-radius: 6px;
  border: 1px solid $LIGHT_GRAY;
  padding: 10px;
  box-sizing: border-box;
  resize: none;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  height: 125px;

  @include responsiveProperty(
    (
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.description:focus-visible {
  outline: none !important;
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.errorBorder {
  border: 1px solid $RED !important;
}

.inputContainer {
  display: flex;
  justify-content: space-between;

  input {
    border: 1px solid $LIGHT_GRAY;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include responsiveProperty(
      (
        "width": 95,
        "height": 36,
      )
    );
  }

  input:focus-visible {
    outline: none !important;
  }
}

.calendarBtn {
  display: flex;
  border: none;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  font-weight: 500;
  padding: 0px 20px;

  @include responsiveProperty(
    (
      "height": 36,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.dateContainer {
  width: 100%;
  div.react-datepicker-wrapper {
    width: 100%;
  }
}

.note {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  margin-left: 5px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.btnContainer {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 24px;
}

.cancelBtn,
.cancelBtn:hover {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  padding: 7px 24px;
  width: fit-content;
  border: 1px solid $LIGHT_GRAY2;
  background-color: $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.addBtn {
  font-weight: 600;
  font-family: "Manrope";
  color: white;
  padding: 7px 24px;
  width: fit-content;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .crossIcon {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .inputLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .selectionContainer {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .description {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .error {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .timeHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .inputContainer {
    input {
      width: calculatePixels(1200, 95) !important;
      height: calculatePixels(1200, 36) !important;
    }
  }
  .calendarBtn {
    width: calculatePixels(1200, 95) !important;
  }
  .note {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .cancelBtn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 650px) {
  .btnContainer {
    flex-direction: column;
  }

  .cancelBtn,
  .addBtn {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .mobileTopMargin {
    margin-top: 24px;
  }
}
