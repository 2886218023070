@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainGrid {
  flex-wrap: nowrap !important;
  @include responsiveProperty(
    (
      //   "width": 177,
      "gap": 14
    )
  );
}

.statsContainer {
 
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  width: 100%;

  @include responsiveProperty(
    (
      "height": 104,
      "padding": 14,
    )
  );
}

.heading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.icon {
  position: absolute;

  @include responsiveProperty(
    (
      "bottom": 14,
      "left": 14,
    )
  );
}

.stats {
  font-weight: 500;
  font-family: "Manrope";
  color: $DARK_GRAY;
  position: absolute;

  @include responsiveProperty(
    (
      "font-size": 24,
      "line-height": 38,
      "bottom": 14,
      "right": 14,
    )
  );
}

@media screen and (max-width: 650px) {
  .mainGrid{
    flex-wrap: wrap !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .stats {
    font-size: calculatePixels(1200, 24) !important;
    line-height: calculatePixels(1200, 38) !important;
  }

  .statsContainer {
    height: calculatePixels(1200, 120) !important;
  }
}
