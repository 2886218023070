@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.taskAccordianContainer {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
  position: relative;
}

.taskAccordianContainer::before {
  background-color: $LIGHT_GRAY !important;
}

.topBorder {
  border-top: 1px solid $LIGHT_GRAY !important;
}

.projectName {
  color: $BLACK2;
  font-weight: 400;
  cursor: pointer;
  padding-left: 26px;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.taskName {
  color: $BLACK2;
  font-weight: 400;
  cursor: pointer;
  padding-left: 26px;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.mainAccordianContainer {
  box-shadow: none !important;
  border: 1px solid $LIGHT_GRAY !important;
  border-radius: 8px !important;
  position: relative;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  @include responsiveProperty(
    (
      "margin-left": 14,
    )
  );
}

.labelTag {
  font-weight: 500;
  color: $GRAY;
  padding: 7px 10px 7px 10px !important;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

// .accordianSummary,
// .taskAccordianContainer {
//   div {
//     align-items: center !important;
//   }
// }

.iconContainer {
  margin-top: 2px;
  margin-left: 14px;

  display: flex;
  align-items: center;
}

.serviceName {
  color: $GRAY;
  cursor: pointer;
  font-weight: 500;
  margin-left: 8px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.smallHeading {
  color: $GRAY;
  cursor: pointer;
  font-weight: 500;
  margin-left: 8px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.accordianSummary {
  padding: 0px !important;
}

.accordianInnerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.accordianNameContainer {
  display: flex;
  align-items: center;
}

.totalTime {
  color: $BLACK2;
  cursor: pointer;
  font-weight: 400;
  margin-left: 8px;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.totalTaskTime {
  color: $BLACK2;
  cursor: pointer;
  font-weight: 400;
  margin-left: 8px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}


.tableRow {
  td {
    border: none !important;
  }
}

.table {
  min-width: none !important;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY !important;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;
  display: table-cell !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  display: table-cell !important;
  font-weight: 400;
  color: $BLACK2;
  font-family: "Manrope" !important;
  max-width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .labelTag {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1200, 14) !important;
  }
  .smallHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .totalTime{
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .totalTaskTime{
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .tableHead {
    th {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
    }
  }
  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}


@media screen and (max-width: 600px) {
  .tableCell2 {
    min-width: 150px !important;
  }
}