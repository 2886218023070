@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.filterContainer {
  display: flex;
  gap: 10px;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_YELLOW;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
}

.labelTag {
  font-weight: 500;
  color: $BROWN;
  padding: 7px 10px 7px 10px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.tagContainer2 {
  display: flex;
  align-items: center;
  background-color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  border: 1px solid $LIGHT_GRAY;
  margin-left: 10px;
}

.labelTag2 {
  font-weight: 500;
  color: $GRAY;
  padding: 7px 10px 7px 10px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.resetBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $GRAY;
  cursor: pointer;
  margin-left: 15px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableMainContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px !important;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.tableHeading {
  width: 100%;
  background-color: $LIGHT_GRAY2;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
}

.tableRow {
  td {
    border: none !important;
  }
}

.table {
  min-width: none !important;
  border: 1px solid $LIGHT_GRAY;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY !important;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;
  display: table-cell !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  display: table-cell !important;
  font-weight: 400;
  color: $BLACK2;
  font-family: "Manrope" !important;
  max-width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.tableMainContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px !important;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.tableHeading {
  display: flex;
  align-items: center;
  padding: 16px;
  width: auto;
  background-color: $LIGHT_GRAY2;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom: 1px solid $LIGHT_GRAY;
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .labelTag {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .labelTag2 {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .resetBtn {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableMainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .tableMainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
}

@media screen and (max-width: 600px){
  .actionContainer{
    display: block !important;
  }

  .tagContainer{
    margin-top: 20px !important;
  }
}


@media screen and (max-width: 800px){
  .tableCell{
    min-width: 150px !important
  }
  .tableCell2{
    min-width: 150px !important
  }
}
