@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  height: 83%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.gridContainer {
  height: 100%;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;
}

.pendingContainer {
  border-right: 1px solid $LIGHT_GRAY;
}

.headingContainer {
  border-bottom: 1px solid $LIGHT_GRAY;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
}

.pendingHeading {
  padding: 3px 10px;
  background-color: $LIGHT_BLUE;
  border-radius: 24px;
  color: $BLUE;
  font-weight: 600;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 24,
    )
  );
}

.approvedHeading {
  padding: 3px 10px;
  background-color: $LIGHT_GREEN;
  border-radius: 24px;
  color: $GREEN_3;
  font-weight: 600;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 24,
    )
  );
}

.requestContainer {
  height: 62vh;
  overflow: scroll;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: #f2f2f2 !important;
  }

  &::-webkit-scrollbar {
    width: 20px !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 9999px !important;
    background-color: $LIGHT_RED !important;
  }

  scrollbar-color: #f2f2f2 transparent !important;
  scrollbar-width: thin !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .pendingHeading {
    font-size: calculatePixels(1200, 14) !important ;
    line-height: calculatePixels(1200, 24) !important ;
  }
  .approvedHeading {
    font-size: calculatePixels(1200, 14) !important ;
    line-height: calculatePixels(1200, 24) !important ;
  }
}

@media screen and (max-width: 1200px) {
  .mainContainer {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .mainContainer {
    padding: 16px !important;
  }
  .pendingContainer{
    border-right: none !important;
  }
  .approvedContainer{
    border-top: 1px solid $LIGHT_GRAY;

  }
}

