@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  height: 90%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingContainer2 {
  display: flex;
  align-items: center;
}

.heading {
  font-weight: 500;
  margin-bottom: 4px !important;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.btn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "height": 36,
      "width": 131,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  @include responsiveProperty(
    (
      "padding-right": 16,
    )
  );
}

.mainMessagesContainer {
  border: 1.5px solid $LIGHT_GRAY;
  height: 86%;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.grid {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.chatListGrid {
  border-right: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "padding": 16,
    )
  );
}

.messageCardGrid {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }

  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .btn {
    height: calculatePixels(1200, 36) !important;
    width: calculatePixels(1200, 131) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .label {
    padding-right: calculatePixels(1200, 16) !important;
  }
  .mainMessagesContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
}
