@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

#inputStyle {
  color: $BLACK;
}

.icon {
  width: 100%;
  box-sizing: border-box;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;
  display: flex;
  align-items: center;
  color: $BLACK;

  border: 1px solid $LIGHT_GRAY;
  background-color: $LIGHT_GRAY2;
  border-radius: 6px;
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  justify-content: space-between;

  label {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 150px;
  }

  @include responsiveProperty(
    (
      "height": 36,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.errorBorder {
  border: 1px solid $RED !important;
}

.inputLabel {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  span {
    color: $RED;
  }
}

.updateBtn {
  width: fit-content;
  padding: 7px 18px;
}

.btnContainer {
  display: flex;
  justify-content: end;
  margin-top: 24px;
}

.serviceContainer {
  display: flex;
  gap: 24px;
}

.singleServiceContainer {
  border: 1px solid $LIGHT_GRAY;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  min-height: 510px;
  overflow: hidden;
}

.topServiceContainer {
  display: flex;
  justify-content: space-between;
}

.serviceHeadingContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.serviceName {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
    )
  );
}

.serviceDescription {
  font-family: "Manrope";
  font-weight: 300;
  color: $BLACK2;
  width: 100% !important;
  min-height: 60px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 19,
    )
  );
}

.serviceIcon {
  @include responsiveProperty(
    (
      "width": 40,
      "height": 40,
    )
  );
}

.serviceAmount {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 24,
      "line-height": 36,
    )
  );

  label {
    font-family: "Manrope";
    font-weight: 500;
    color: $GRAY;

    @include responsiveProperty(
      (
        "font-size": 12,
        "line-height": 16,
        "margin-left": 3,
      )
    );
  }
}

.serviceSubHeadingContainer {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 14px;
}

.serviceSubHeading {
  font-family: "Manrope";
  font-weight: 400;
  color: $BLACK2;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.btn {
  background-color: transparent !important;
  color: $BLACK !important;
  font-family: "Manrope";
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;
  padding: 7px 16px;
  font-weight: 500;
  height: auto !important;

  svg {
    path {
      stroke: $BLACK;
    }
  }

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.serviceContainerTopLevel {
  height: 100%;
  box-sizing: border-box;

  @include responsiveProperty(
    (
      "padding": 43,
    )
  );
}

.pointContainer {
  margin-top: 24px;
}

.singleServicePoint {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 16,
    )
  );
}

.preview {
  background-color: $LIGHT_GRAY2;
  padding: 11px 16px;

  label {
    font-family: "Manrope" !important;
    font-weight: 500;

    @include responsiveProperty(
      (
        "font-size": 12,
        "line-height": 16,
      )
    );
  }
}

.iconService {
  height: 40px;
  width: 40px;
}

.phoneNumber {
  border: 1px solid $LIGHT_GRAY;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 8px;

  div {
    input {
      width: 100% !important;
      box-sizing: border-box !important;
      font-weight: 500 !important;
      font-family: "Manrope" !important;
      color: $BLACK !important;
      font-size: 14px !important;
    }
  }
}

.infoContainers {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}

.infoHeading {
  font-family: "Manrope" !important;
  font-weight: 500;
  color: $BLACK;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.infoDescription {
  font-family: "Manrope" !important;
  font-weight: 500;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.infoSingleContainer {
  overflow: hidden;
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.contactBtn {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2 !important;
  background-color: transparent !important;
  width: fit-content !important;
  border: 1px solid $BLACK;
  padding: 7px 16px;
  margin-top: 10px;
  height: auto !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.contactIcon {
  margin-left: 10px;
}

.contactInfo {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 8s linear infinite;
}

.iconSpin {
  position: absolute;
  right: -20px;
  bottom: -20px;

  @include responsiveProperty(
    (
      "height": 123,
      "width": 126.39,
    )
  );
}

@media screen and (max-width: 500px) {
  .iconSpin {
    height: calculatePixels(900, 123) !important;
    width: calculatePixels(900, 127) !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer,
  .infoSingleContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .inputLabel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .icon {
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .serviceName {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }
  .serviceDescription {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 19) !important;
  }
  .serviceIcon {
    height: calculatePixels(1200, 40) !important;
    width: calculatePixels(1200, 40) !important;
  }
  .serviceAmount {
    font-size: calculatePixels(1200, 24) !important;
    line-height: calculatePixels(1200, 36) !important;

    label {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
      margin-left: calculatePixels(1200, 3) !important;
    }
  }
  .serviceSubHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .preview {
    label {
      font-size: calculatePixels(1200, 12) !important;
      line-height: calculatePixels(1200, 16) !important;
    }
  }
  .serviceContainerTopLevel {
    padding: calculatePixels(1200, 43) !important;
  }
  .contactBtn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .contactInfo {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .serviceContainerTopLevel {
    padding: 18px !important;
  }
}
