.customContainer {
  margin: auto;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w20 {
  width: 20% !important;
}

.w30 {
  width: 30% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w90 {
  width: 90% !important;
}

.h100 {
  height: 100% !important;
}

@mixin elipsis($val) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $val;
  -webkit-box-orient: vertical;
}

@function calculateRatio($base-size, $ratio) {
  $result: $base-size * $ratio;
  @if ($result < 0) {
    @return $base-size * $ratio;
  } @else if ($result > 10) {
    @return $base-size * $ratio;
  } @else {
    @return 9;
  }
}

@mixin responsiveProperty($properties) {
  @include spreadProperty($properties, 1);

  @media screen and (min-width: 768px) {
    @include spreadProperty($properties, 992/1400);
  }

  @media screen and (min-width: 992px) {
    @include spreadProperty($properties, 992/1400);
  }

  @media screen and (min-width: 1200px) {
    @include spreadProperty($properties, 1200/1400);
  }

  @media screen and (min-width: 1400px) {
    @include spreadProperty($properties, 1400/1400);
  }

  @media screen and (min-width: 1600px) {
    @include spreadProperty($properties, 1600/1400);
  }

  @media screen and (min-width: 1800px) {
    @include spreadProperty($properties, 1800/1400);
  }

  @media screen and (min-width: 2000px) {
    @include spreadProperty($properties, 2000/1400);
  }
  @media screen and (min-width: 2200px) {
    @include spreadProperty($properties, 2000/1400);
  }
  @media screen and (min-width: 2400px) {
    @include spreadProperty($properties, 2000/1400);
  }
  @media screen and (min-width: 2600px) {
    @include spreadProperty($properties, 2000/1400);
  }
}

@mixin MRP($properties) {
  @include spreadProperty($properties, 1);

  @media screen and (min-width: 568px) {
    @include spreadProperty($properties, 992/1400);
  }

  @media screen and (min-width: 768px) {
    @include spreadProperty($properties, 992/1400);
  }

  @media screen and (min-width: 992px) {
    @include spreadProperty($properties, 1200/1400);
  }

  @media screen and (min-width: 1200px) {
    @include spreadProperty($properties, 1200/1400);
  }

  @media screen and (min-width: 1400px) {
    @include spreadProperty($properties, 1200/1400);
  }
  @media screen and (min-width: 1600px) {
    @include spreadProperty($properties, 1400/1400);
  }
}

@mixin spreadProperty($myArray, $ratioValue) {
  @each $name, $value in $myArray {
    $key: $name;
    $value: calculateRatio($value, $ratioValue) + px;
    // Use the key-value pairs in your styles
    #{$key}: $value !important;
  }
}

@function calcultn($ratio, $value) {
  $result: $value * $ratio;
  @if ($result > 10) {
    @return $result + px;
  } @else {
    @return 9px;
  }
}

@function calculatePixels($screenSize, $value) {
  $ratio: $screenSize/1400;
  $result: $value * $ratio;
  @return $result + px;
}

@media screen and (max-width: 576px) {
  .customContainer {
    max-width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .customContainer {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .customContainer {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .customContainer {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .customContainer {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .customContainer {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1600px) {
  .customContainer {
    max-width: 1500px;
  }
}

@media screen and (min-width: 1800px) {
  .customContainer {
    max-width: 1688px;
  }
}

@media screen and (min-width: 2000px) {
  .customContainer {
    max-width: 1875px;
  }
}
