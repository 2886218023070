@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.iconReverse {
  transform: scaleY(-1);
}

.accordian {
  box-shadow: none !important;
  position: auto !important;
  div {
    padding: 0px;
  }
}

.accordian::before {
  background-color: transparent !important;
}

.icon {
  margin-right: 11px;
}

.tasksHeading {
  cursor: pointer;
  font-weight: 600;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.taskName {
  font-weight: 600;
  font-family: "Manrope" !important;
  cursor: pointer;
  color: $DARK_GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.taskDate {
  font-weight: 600;
  font-family: "Manrope" !important;
  cursor: pointer;
  color: $GRAY;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.accordianSummay {
  div {
    margin-bottom: 0px !important;
  }
}

.accordianSummayContainer {
  padding-bottom: 15px !important;
  border-bottom: 1px solid $LIGHT_GRAY;
  width: 100%;
}

.expandIconWrapper {
  display: flex;
  align-items: center;
}

.expandIconWrapper .MuiSvgIcon-root {
  margin-right: 8px;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  cursor: pointer;
  @include responsiveProperty(
    (
      "margin-left": 7,
    )
  );
}

.labelTag {
  font-weight: 500;
  color: $GRAY;
  padding: 4px 12.5px;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.singleTaskContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.iconsContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
  }
}

.taskAccordian {
  padding: 0px 10px !important;
  background: $LIGHT_GRAY2 !important;
  border-radius: 8px !important;
  margin: 10px 0px;
}

.taskDescription {
  font-weight: 400;
  color: $BLACK;
  padding: 10px !important;
  box-sizing: border-box;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tasksHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1200, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .taskName {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .taskDate {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .taskDescription {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 450px) {
  .tagContainer {
    margin-left: calculatePixels(1150, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1150, 12) !important;
    line-height: calculatePixels(1150, 16) !important;
  }
}
