@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  width: fit-content;
  bottom: 0px;
  position: static !important;

  @include responsiveProperty(
    (
      "padding-bottom": 30,
    )
  );
}

.footerCopyright {
  font-family: "Manrope";
  font-weight: 400;

  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.footerLabel {
  font-family: "Manrope";
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

@media screen and (max-width: 900px) {
  .mainContainer {
    position: relative !important;
    margin-top: 80px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
  }

  .footerLabel {
    margin-left: 0px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  .mainContainer {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer {
    margin-top: 60px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footerCopyright {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .footerLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}
