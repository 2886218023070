@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

#paid {
  padding: 3px 10px !important;
  background-color: $LIGHT_GREEN;
  color: $GREEN_3;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}


#withdrawn {
  padding: 3px 10px !important;
  background-color: $LIGHT_BLUE;
  color: $BLUE;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#cancelled {
  padding: 3px 10px !important;
  background-color: $LIGHT_RED;
  color: $RED_2;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}


@media (min-width: 768px) and (max-width: 1200px) {
  #paid {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  #withdrawn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  #cancelled {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}
