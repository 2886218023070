@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.outerMostContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.mainContainer {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "width": 528,
      "margin-top": 25,
    )
  );
}

.logo {
  @include responsiveProperty(
    (
      "width": 176.85,
      "height": 27.62,
    )
  );
}

.heading {
  margin: 0px;
  font-family: "Manrope";
  font-weight: 400;
  text-align: center;

  @include responsiveProperty(
    (
      "font-size": 20,
      "line-height": 34,
      "margin-top": 5,
    )
  );
}

.subHeading {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  text-align: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 25,
      "margin-top": 5,
    )
  );
}

.btn {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.backBtn {
  background-color: $LIGHT_GRAY !important;
  color: $GRAY !important;
  border: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "margin-top": 10,
    )
  );
}

.footer {
  position: fixed !important;
  padding: 40px;
}

.circledTick {
  @include responsiveProperty(
    (
      "width": 56,
      "height": 56,
    )
  );
}

.conditonLabel {
  font-family: "Manrope";
  font-weight: 500;
  color: $RED;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.conditionSuccess{
  color: $GREEN;
}

.conditionContainer{
  display: flex;
  justify-content: space-around;
  @include responsiveProperty(
    (
      "margin-top": 16,
      "margin-bottom": 16,
    )
  );
}

.conditionIcon{
  margin-right: 5px;
  height: 15px;
  width: 15px;
}

@media screen and (max-width: 500px) {
  .mainContainer {
    width: 80% !important;
  }

  .conditonLabel{
    font-size: 12px !important;
  }

  .outerMostContainer{
    margin-top: -50px;
  }

}
