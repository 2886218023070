@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.innerContainer {
  display: flex !important;
  justify-content: space-between !important;
}

.line {
  height: 1px;
  width: 100%;
  background-color: $LIGHT_GRAY;
}

.requestsCard {
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.timeStamp {
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.dateStamp {
  display: flex;
  color: $GRAY;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-bottom": 30,
    )
  );

  label {
    @include responsiveProperty(
      (
        "width": 220,
      )
    );
  }
}

.profile_pic {
  @include responsiveProperty(
    (
      "height": 46,
      "width": 46,
      "margin-right": 15,
    )
  );
}

.name {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "width": 115,
    )
  );
}

.contentContainer {
  @include responsiveProperty(
    (
      "width": 268,
    )
  );
}

.file {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  min-width: 202px;

  @include responsiveProperty(
    (
      "height": 68,
      // "min-width": 202,
      "margin-top": 15,
    )
  );
}

.fileIconContainer {
  background-color: white;
  width: fit-content;
  padding: 10px;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
}

.description {
  color: $DARK_GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "margin-top": 6,
    )
  );
}

.iconContainer {
  svg {
    margin-left: 10px;
    cursor: pointer;
  }
}

.size {
  color: $GRAY;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.fileInnerContainer {
  display: flex;
  width: 85%;
  @include responsiveProperty(
    (
      "padding": 12,
    )
  );
}

.gray {
  color: $GRAY;
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

.fileOptionsBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.edited {
  color: $RED_3;
  margin-left: 5px;
}

.editContainer {
  border: 1px solid $LIGHT_GRAY;
  background-color: $LIGHT_GRAY2;
  border-radius: 6px;

  @include responsiveProperty(
    (
      "margin-top": 25,
      "padding-top": 14,
      "padding-bottom": 14,
      "padding-right": 24,
      "padding-left": 24,
    )
  );
}

.textarea {
  resize: none;
  border: none;
  width: 100%;
  background-color: transparent;
  font-family: "Manrope" !important;
  color: $DARK_GRAY;
  min-height: 100px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.textarea:focus-visible {
  outline: none;
}

.cancelEdit {
  background-color: white !important;
  border: 1px solid $LIGHT_GRAY;
  padding: 20px !important;
  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.saveEdit {
  padding: 20px !important;

  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.buttonContainer {
  display: flex;
  justify-content: end;
  gap: 10px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .requestsCard {
    padding: calculatePixels(1200, 24) !important;
  }
  .profile_pic {
    height: calculatePixels(1200, 46) !important;
    width: calculatePixels(1200, 46) !important;
    margin-right: calculatePixels(1200, 15) !important;
  }
  .contentContainer {
    width: calculatePixels(1200, 268) !important;
  }
  .file {
    height: calculatePixels(1200, 68) !important;
    margin-top: calculatePixels(1200, 15) !important ;
  }
  .dateStamp {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    margin-bottom: calculatePixels(1200, 30) !important;

    label {
      width: calculatePixels(1200, 220) !important;
    }
  }
  .contentContainer {
    width: calculatePixels(1200, 268) !important;
  }
  .name {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .description {
    margin-top: calculatePixels(1200, 6) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .size {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .fileInnerContainer {
    padding: calculatePixels(1200, 12) !important;
  }
  .name {
    width: calculatePixels(1200, 115) !important;
  }
  .editContainer {
    margin-top: calculatePixels(1200, 25);
    padding-top: calculatePixels(1200, 14) !important;
    padding-bottom: calculatePixels(1200, 14) !important;
    padding-right: calculatePixels(1200, 24) !important;
    padding-left: calculatePixels(1200, 24) !important;
  }
  .textarea {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .cancelEdit {
    width: calculatePixels(1200, 65) !important;
  }
  .saveEdit {
    width: calculatePixels(1200, 65) !important;
  }
}

@media screen and (max-width: 600px) {
  .innerContainer {
    display: block !important;
  }
  .iconContainer {
    margin-top: 20px;
  }
  .contentContainer {
    width: 70% !important;
  }
  .fileIconContainer {
    width: auto !important;
  }
  .name {
    width: 90px !important;
  }
  .cancel {
    padding: 20px !important;
  }
  .confirm {
    padding: 20px !important;
  }
}
