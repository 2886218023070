@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer{
  height: 100%;
}

.mainGrid {
  height: 100% !important;
}

.rightGrid {
  display: flex !important;
  flex-direction: column !important;
  @include responsiveProperty(
    (
      "padding-right": 30,
      "gap": 20,
    )
  );
}

.leftGrid {
  display: flex;
  flex-direction: column !important;

  @include responsiveProperty(
    (
      "gap": 20,
    )
  );
}

@media screen and (max-width: 900px) {
  .rightGrid {
    padding-right: 0px !important;
    padding-bottom: 30px !important;
  }

  .mainContainer{
    height: fit-content;
  }
}
