@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.label {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.crossIcon {
  cursor: pointer;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.singleInfoContainer {
  overflow: hidden;
  position: relative;
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.heading {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-family: "Manrope";
  font-weight: 500;
  color: $GRAY;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.contactInfo {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.icon {
  position: absolute;
  right: -20px;
  bottom: -20px;

  @include responsiveProperty(
    (
      "height": 123,
      "width": 126.39,
    )
  );
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 8s linear infinite;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.selectedTime {
  font-family: "Manrope";
  font-weight: 600;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .crossIcon {
    width: calculatePixels(1200, 24) !important;
    height: calculatePixels(1200, 24) !important;
  }
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .singleInfoContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .contactInfo {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .selectedTime {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 500px) {
  .icon {
    height: calculatePixels(900, 123) !important;
    width: calculatePixels(900, 127) !important;
  }
}

@media screen and (max-width: 600px) {
  .content {
    padding: 0px !important;
  }
}
