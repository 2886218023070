@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.logo {
  width: 100%;
  height: 100px;
  margin: 25px 0px;
}

.sidebar {
  height: 100vh;
  @include responsiveProperty(
    (
      "width": 300,
    )
  );
}

.menu {
  ul {
    li {
      color: var(--gray-gray-07, $GRAY);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      a {
        margin: 0px 32px;
        padding: 10px !important;
      }
    }
  }
}

.active {
  a {
    color: $RED !important;
    border-radius: 5px !important;
    background-color: $SELECT_GRAY;
  }
}

.menuItems{
  a,a:hover {
    color: $GRAY !important;
    border-radius: 5px !important;
    font-weight: 500;
    font-family: "Manrope" !important;
    font-size: 14px;
    line-height: 22px;
    height: 56px !important;
  }
}

.dashboardTab {
  a,
  a:hover {
    background-color: $RED !important;
    color: white !important;
    font-weight: 500;
    font-family: "Manrope" !important;
    border-radius: 10px !important;
    font-size: 16px;
    line-height: 23px;
    height: 57.29px !important;
    margin-bottom: 20px !important;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }
}

.subMenuContainer {
  div {
    background-color: $GRAY;
    margin: 10px;

    ul {
      li {
        a {
          margin: 0px !important;
        }
      }
    }
  }
}

.borderSubMenu {
  border-bottom: 1px solid $GRAY;
}

.subMenuActive {
  color: $RED !important;
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding: 26px;
  cursor: pointer;
}

.componentContainer {
  padding: 24px;
  background-color: $BACKGROUND;
  height: 90vh;
  overflow: scroll;
  box-sizing: border-box;
  position: relative;
}

.componentContainer::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .logo {
    width: 75%;
  }
}

@media screen and (max-width: 1440px){
  .sidebar{
    max-width: 250px !important;
    width: 250px !important
  }
}
