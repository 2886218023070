@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  min-height: 90%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.infoContainers {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infoContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.heading {
  font-family: "Manrope";
  font-weight: 500;
  color: $BLACK2;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-family: "Manrope";
  font-weight: 500;
  color: $GRAY;
  text-align: start;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 16px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.calendarIcon {
  margin-left: 10px;
}

.calendarBtn {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2 !important;
  background-color: transparent !important;
  width: fit-content !important;
  border: 1px solid $BLACK;
  padding: 7px 16px;
  margin-top: 14px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.monthLabel {
  width: 100%;
  font-weight: 500;
  font-family: "Manrope" !important;
}

.dateCalendarBtn {
  background-color: white !important;
  border: none !important;
  cursor: pointer;
  width: fit-content;
  display: flex;
}

.alignedContainer {
  display: flex;
  align-items: center;
}

.selectedDateContainer {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.selectedDate {
  font-family: "Manrope";
  font-weight: 600;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.arrowIcon {
  cursor: pointer;
}

.menuItems {
  font-weight: 400 !important;
  color: $BLACK !important;
  font-family: "Manrope" !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.meetingCard {
  display: flex;
  padding: 14px;
  margin-bottom: 14px;
}

.meetingCard:hover {
  background-color: $LIGHT_GRAY2;
}

.content {
  display: flex;
  padding-left: 24px;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: 24px;
}

.timeHeading {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK2;

  @include responsiveProperty(
    (
      "font-size": 18,
      "line-height": 26,
    )
  );
}

.timeSubHeading {
  font-family: "Manrope";
  font-weight: 600;
  color: $LIGHT_GRAY4;

  @include responsiveProperty(
    (
      "font-size": 10,
      "line-height": 14,
    )
  );
}

.name {
  font-family: "Manrope";
  font-weight: 600;
  margin-right: 10px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.serviceName {
  font-family: "Manrope";
  font-weight: 600;
  color: $GRAY;
  margin-left: 6px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.meetingName {
  font-family: "Manrope";
  font-weight: 600;
  color: $BLACK;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.meetingDescription {
  font-family: "Manrope";
  font-weight: 500;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.picture {
  width: 52px !important;
  height: 52px !important;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 14px;
}

.action {
  background-color: transparent;
  border: none !important;
  cursor: pointer;
}

.meetingContainer {
  height: auto !important;
  overflow: scroll;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer,
  .infoContainer {
    padding: calculatePixels(1200, 24) !important;
  }

  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .calendarBtn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .selectedDate {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .menuItems {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .timeHeading {
    font-size: calculatePixels(1200, 18) !important;
    line-height: calculatePixels(1200, 26) !important;
  }
  .timeSubHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .name {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .serviceName {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .meetingName,
  .meetingDescription {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}

@media screen and (max-width: 500px) {
  .meetingCard {
    display: block !important;
  }
  .content {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .mainGrid {
    display: flex;
    flex-direction: column-reverse !important;
  }
}
