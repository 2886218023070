@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.icon {
  @include responsiveProperty(
    (
      "margin-left": 15,
    )
  );
}

.btn {
  color: $GRAY !important;
  background-color: white !important;
  font-weight: 400;
  border: 1.5px solid $LIGHT_GRAY;
  box-sizing: border-box;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding": 15,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .icon {
    margin-left: calculatePixels(1150, 14) !important;
  }

  .btn {
    font-size: calculatePixels(1150, 14) !important;
    line-height: calculatePixels(1150, 22) !important;
    padding: calculatePixels(1150, 22) !important;
  }
}
