@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.upperActionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.label {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.label2 {
  color: $GRAY;
  font-family: "Manrope" !important;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.dateHeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.day {
  color: $GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  text-align: center;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.date {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  font-weight: 600;
  text-align: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.timeContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  cursor: pointer;
}

.timeStamp {
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  font-weight: 500;
  text-align: center;
  padding: 7px 10px;
  border-radius: 6px;
  border: 1px solid $LIGHT_GRAY;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "min-width": 50,
    )
  );
}

.active {
  background-color: $RED;
  border: 1px solid $RED;
  color: white !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .label {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .label2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .day {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .date {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    min-width: calculatePixels(1200, 50) !important;
  }
}

@media screen and (max-width: 800px) {
  .dateContainer {
    width: 800px;
  }
  .forMobileContainer {
    overflow: scroll;
    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    scrollbar-width: 0px !important;
  }

  .upperActionContainer {
    display: block;
  }
  .label2,
  .label {
    display: flex;
    width: 100% !important;
    margin-bottom: 10px;
  }
}
