@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.input {
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;

  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;

  @include responsiveProperty(
    (
      "height": 36,
      "margin-top": 10,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.input:focus-visible {
  outline: none !important;
}

.input::placeholder {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  span {
    color: $RED;
  }
}

.passwordIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 8px;
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.loader {
  position: absolute;
  color: $RED !important;
  width: 17px !important;
  height: 17px !important;
  top: 17px;
  right: 8px;
}

.errorBorder {
  border: 1px solid $RED;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .input {
    height: calculatePixels(1200, 36) !important;
    margin-top: calculatePixels(1200, 10) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .input::placeholder {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .label {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .error {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
