@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  font-weight: 500;
  color: $DARK_GRAY;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.error {
  font-weight: 400;
  font-family: "Manrope";
  color: $RED;
  margin-top: 3px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.headingContainer {
  padding-bottom: 16px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.addBtn {
  cursor: pointer;
  border: 1px solid $LIGHT_GRAY;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @include responsiveProperty(
    (
      "width": 36,
      "height": 36,
      "margin-left": 14,
    )
  );
}

.photoContainer {
  display: flex;
  align-items: center;
}

.profile_picture {
  border-radius: 50%;
  object-fit: cover;
  @include responsiveProperty(
    (
      "width": 88,
      "height": 88,
    )
  );
}

.changePwdBtn {
  font-weight: 600;
  color: $GRAY;
  padding: 7px 12px;
  border: 1px solid $LIGHT_GRAY;
  background-color: $LIGHT_GRAY2;
  font-family: "Manrope" !important;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.btnContainerClass {
  width: fit-content;
}

.btnClass {
  padding: 7px 12px;
}

.accountEditContainer {
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.googleIcon {
  @include responsiveProperty(
    (
      "width": 22,
      "height": 22,
    )
  );
}

.googleBtn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $LIGHT_GRAY !important;
  width: 420px;
  padding: 7px 12px;
}

.googleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
  padding-bottom: 24px;
}

.deleteContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sectionSubHeading {
  font-weight: 400;
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.passwordHeading {
  font-weight: 500;
  color: $GRAY;
  font-family: "Manrope" !important;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.sectionSubHeading2 {
  font-weight: 600;
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.sectionSubHeading3 {
  font-weight: 500;
  color: $DARK_GRAY;
  font-family: "Manrope" !important;
  width: 100% !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );

  span {
    color: $RED;
  }
}

.deleteContainer {
  width: fit-content;
}

.circledTick {
  @include responsiveProperty(
    (
      "width": 56,
      "height": 56,
    )
  );
}

.conditonLabel {
  font-family: "Manrope";
  font-weight: 500;
  color: $RED;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.conditionSuccess {
  color: $GREEN;
}

.conditionContainer {
  display: flex;
  justify-content: space-around;
  width: 50%;
  @include responsiveProperty(
    (
      "margin-top": 16,
      "margin-bottom": 16,
    )
  );
}

.conditionIcon {
  margin-right: 5px;
  height: 15px;
  width: 15px;
}

.passwordsContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.buttonContainer {
  display: flex;
  justify-content: end;
  gap: 10px;

  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.cancelEdit {
  background-color: white !important;
  border: 1px solid $LIGHT_GRAY;
  padding: 20px !important;
  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.saveEdit {
  padding: 20px !important;

  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

#input {
  width: fit-content;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }

  .addBtn {
    width: calculatePixels(1200, 36) !important;
    height: calculatePixels(1200, 36) !important;
    margin-left: calculatePixels(1200, 14) !important;
  }

  .profile_picture {
    width: calculatePixels(1200, 88) !important;
    height: calculatePixels(1200, 88) !important;
  }

  .changePwdBtn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .googleIcon {
    width: calculatePixels(1200, 22) !important;
    height: calculatePixels(1200, 22) !important;
  }

  .sectionSubHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .sectionSubHeading3,
  .sectionSubHeading2 {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 600px) {
  .googleBtn {
    width: 100%;
  }
  .changePwdBtn {
    width: 100%;
  }
  .btnContainerClass {
    width: 100%;
  }
  #input {
    width: 100%;
  }
  .conditionContainer {
    width: 100%;
  }
  .conditonLabel {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 900px) {
  .changePwdBtn {
    margin-bottom: 20px;
  }
}
