@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.tab {
  margin-top: 10px;
}

.labelTab {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  text-transform: none !important;
  padding: 0px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.active {
  color: $BLACK !important;
}

.searchContainer {
  background-color: $LIGHT_GRAY2;
  width: 100%;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "height": 36,
    )
  );

  input {
    border: none !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-weight: 500;
    font-family: "Manrope";
    color: $GRAY;
    border-radius: 6px;
    background-color: $LIGHT_GRAY2;

    @include responsiveProperty(
      (
        "font-size": 14,
        "line-height": 22,
      )
    );
  }

  input:focus-visible {
    outline: none !important;
  }

  svg {
    padding: 0px 10px;
  }
}

.singleChatContainer {
  @include responsiveProperty(
    (
      "padding": 8,
    )
  );
  cursor: pointer;
}

.singleChatContainer:hover {
  background-color: $LIGHT_GRAY2 !important;
  border-radius: 8px;
}

.imgContainer {
  margin-right: 20px;
  width: fit-content;
}

.profileListPic {
  @include responsiveProperty(
    (
      "width": 46,
      "height": 46,
    )
  );
}

.onlineChat {
  background-color: white;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  right: 0px;
}

.online {
  background: $GREEN_2;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.nameList {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $BLACK !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

//

.timeList {
  font-weight: 400 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  margin-left: 5px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.newMsgCounter {
  background-color: $RED;
  color: white;
  font-weight: 400 !important;
  font-family: "Manrope" !important;
  padding: 2px 10px;
  border-radius: 10px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.lastMsg {
  font-weight: 300 !important;
  font-family: "Manrope" !important;
  color: $DARK_GRAY !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "width": 212,
    )
  );
}

.chatList {
  padding: 0px 10px;
  height: 48vh;
  overflow: scroll;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #d9d9d9;
  }

  scrollbar-color: #d9d9d9 transparent !important;
  scrollbar-width: thin !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .labelTab {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .searchContainer {
    height: calculatePixels(1200, 36) !important;

    input {
      font-size: calculatePixels(1200, 14) !important;
      line-height: calculatePixels(1200, 22) !important;
    }
  }
  .singleChatContainer {
    padding: calculatePixels(1200, 8) !important;
  }
  .profileListPic {
    width: calculatePixels(1200, 46) !important;
    height: calculatePixels(1200, 46) !important;
  }
  .nameList {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .timeList {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .newMsgCounter {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .lastMsg {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 140) !important;
  }
}

@media screen and (max-width: 600px) {
  .nameList {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .lastMsg {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 200) !important;
  }

  .timeList {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .newMsgCounter {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}
