@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

#low {
  padding: 3px 10px !important;
  background-color: $LIGHT_GREEN;
  color: $GREEN_3;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#medium {
  padding: 3px 10px !important;
  background-color: $LIGHT_YELLOW;
  color: $BROWN;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}


#high {
  padding: 3px 10px !important;
  background-color: $LIGHT_RED;
  color: $BLUE;
  border-radius: 24px !important;
  width: fit-content;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}


@media (min-width: 768px) and (max-width: 1200px) {
  #low {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  #medium {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  #high {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
}
