@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.alert {
  background-color: #ff2626;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 10px;
}

.button {
  background-color: $RED;
  width: 100%;
  border: none;
  box-shadow: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Manrope";
  font-weight: 600;
  text-transform: none;

  @include responsiveProperty(
    (
      "height": 36,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.img {
  margin-right: 10px;
  @include responsiveProperty(
    (
      "width": 24,
      "height": 24,
    )
  );
}

.button:hover {
  background-color: $RED;
}

.label {
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .button {
    height: calculatePixels(1200, 36) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .img {
    width: calculatePixels(1150, 24) !important;
    height: calculatePixels(1150, 24) !important;
  }
}
