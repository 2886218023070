@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  height: 100%;
}

.mainGrid {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.rightGrid {
  border-right: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "padding-top": 24,
      "padding-bottom": 24,
      "padding-right": 24,
    )
  );
}

.leftGrid {
  @include responsiveProperty(
    (
      "padding-top": 24,
      "padding-bottom": 24,
    )
  );
}

.description {
  padding: 14px;
  padding-left: 44px !important;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  font-family: "Manrope" !important;
  color: $DARK_GRAY;

  @include responsiveProperty(
    (
      "height": 160,
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.description:focus-visible {
  outline: none !important;
}

.descriptionIcon {
  position: absolute;
  top: 16px;
  left: 14px;
}

.commentArea {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  resize: none;
  transition: height 0.3s ease;
  border: 1px solid $LIGHT_GRAY;
  border-radius: 6px;
  font-family: "Manrope";
  font-weight: 500;

  &.commentFocused {
    height: 140px;
    transition: height 0.3s ease;
    border-radius: 6px;
  }

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding": 8,
      "margin-top": 10,
      "margin-bottom": 10,
    )
  );
}

.paperClip {
  position: absolute;
  right: 10px;
  top: 25px;
}

.paperClipFocused {
  position: absolute;
  bottom: 25px !important;
  left: 35px;
  top: auto;
}

.separator {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 1px;
  background-color: $LIGHT_GRAY;
}

.emojiPickerContainer {
  position: absolute;
  bottom: 20px;
  left: 5px;
}

.commentContainer {
  display: flex;
  align-items: center;
}

.commentArea:focus-visible {
  outline: none !important;
}

.commentArea::placeholder {
  color: $GRAY !important;
}

.addTaskBtn {
  border: 1px solid $LIGHT_GRAY;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 14px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-left: 5px;
  }

  @include responsiveProperty(
    (
      "height": 36,
      "width": 120,
    )
  );

  label {
    color: $GRAY;
    font-weight: 500;
    cursor: pointer;

    @include responsiveProperty(
      (
        "font-size": 14,
        "line-height": 22,
      )
    );
  }
}

.commentBtn {
  padding: 7px 9px;
  width: fit-content;
}

.commentMainContainer {
  display: flex;
  flex-direction: column;
}

.btnContainer {
  display: flex;
  justify-content: end;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .rightGrid {
    padding-top: calculatePixels(1200, 24) !important;
    padding-bottom: calculatePixels(1200, 24) !important;
    padding-right: calculatePixels(1200, 24) !important;
  }
  .leftGrid {
    padding-top: calculatePixels(1200, 24) !important;
    padding-bottom: calculatePixels(1200, 24) !important;
  }
  .description {
    height: calculatePixels(1200, 160) !important;
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .addTaskBtn {
    width: fit-content !important;
    label {
      font-size: calculatePixels(1200, 14) !important;
      line-height: calculatePixels(1200, 22) !important;
    }
  }
}
