@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  font-weight: 500;
  color: $DARK_GRAY;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.headingContainer {
  padding-bottom: 16px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
}

.switchLabel {
  font-family: "Manrope" !important;
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.smallHeading {
  font-family: "Manrope" !important;
  font-weight: 500;
  color: $GRAY;
  margin-left: 4px;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.notificationHeading {
  font-family: "Manrope" !important;
  font-weight: 400;
  color: $BLACK2;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.optionContainer {
  padding-bottom: 14px;
  border-bottom: 1.5px solid $LIGHT_GRAY;
  padding-top: 14px;
}

.note {
  font-family: "Manrope" !important;
  font-weight: 500;
  color: $GRAY;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
  span {
    color: $GREEN;
  }
}

.cancelEdit {
  background-color: white !important;
  border: 1px solid $LIGHT_GRAY;
  padding: 20px !important;
  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.saveEdit {
  padding: 20px !important;

  @include responsiveProperty(
    (
      "width": 65,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

.buttonContainer {
  display: flex;
  justify-content: end;
  gap: 10px;

  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .heading {
    font-size: calculatePixels(1200, 16) !important;
    line-height: calculatePixels(1200, 23) !important;
  }
  .switchLabel {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .smallHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .notificationHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .cancelEdit,
  .saveEdit {
    width: calculatePixels(1200, 65) !important;
  }

  .confirm,
  .cancel {
    padding-top: calculatePixels(1200, 20) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-right: calculatePixels(1200, 35) !important;
    padding-left: calculatePixels(1200, 35) !important;
  }
  .buttonContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
}
