@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  background-color: white !important;
  position: relative;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY3;
  min-height: 90%;

  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.btn {
  background-color: white !important;
  color: $BLACK !important;
  border: 1px solid $BLACK;
  display: flex;
  align-items: center;
  height: 36px !important;
  width: 147px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.label {
  @include responsiveProperty(
    (
      "padding-right": 16,
    )
  );
}

.labelTab {
  font-weight: 500 !important;
  font-family: "Manrope" !important;
  color: $GRAY !important;
  text-transform: none !important;
  padding: 0px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.active {
  color: $BLACK !important;
}

.heading {
  font-weight: 500;
  margin-bottom: 4px !important;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.subHeading {
  font-weight: 500;
  font-family: "Manrope";
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelTag {
  font-weight: 500;
  color: $GRAY;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tagContainer {
  display: flex;
  align-items: center;
  padding: 7px 10px 7px 10px;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  @include responsiveProperty(
    (
      "margin-left": 24,
    )
  );
}

.headingContainer2 {
  display: flex;
  align-items: center;
}

.tabsContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.projectContainer {
  @include responsiveProperty(
    (
      "margin-top": 20,
    )
  );
}

.filterMainContainer {
  display: flex;
  @include responsiveProperty(
    (
      "margin-top": 16,
    )
  );
}

.filterContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include responsiveProperty(
    (
      "gap": 20,
    )
  );
}

.resetBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $GRAY;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.searchBarContainer {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 147) !important;
    height: calculatePixels(1200, 31) !important;
  }
  .labelTab {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .heading {
    font-size: calculatePixels(1200, 20) !important;
    line-height: calculatePixels(1200, 34) !important;
  }

  .subHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 20) !important;
  }
  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1200, 24) !important;
  }
  .projectContainer {
    margin-top: calculatePixels(1200, 20) !important;
  }
  .filterContainer {
    gap: calculatePixels(1200, 20) !important;
  }
  .filterMainContainer {
    margin-top: calculatePixels(1200, 16) !important;
  }
  .resetBtn {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
}

@media screen and (max-width: 650px) {
  .headingContainer {
    display: block !important;
  }

  .btn {
    margin-top: 20px !important;
    width: 250px !important;
  }

  .headingContainer2 {
    justify-content: space-between;
  }
}

@media screen and (max-width: 450px) {
  .mainContainer {
    padding: calculatePixels(1150, 24) !important;
  }
  .btn {
    font-size: calculatePixels(1150, 14) !important;
    line-height: calculatePixels(1150, 22) !important;
  }
  .labelTab {
    font-size: calculatePixels(1150, 14) !important;
    line-height: calculatePixels(1150, 22) !important;
  }
  .heading {
    font-size: calculatePixels(1150, 20) !important;
    line-height: calculatePixels(1150, 34) !important;
  }

  .subHeading {
    font-size: calculatePixels(1150, 14) !important;
    line-height: calculatePixels(1150, 20) !important;
  }
  .labelTag {
    font-size: calculatePixels(1150, 12) !important;
    line-height: calculatePixels(1150, 16) !important;
  }
  .tagContainer {
    margin-left: calculatePixels(1150, 24) !important;
  }
}

@media screen and (max-width: 990px) {
  .filterMainContainer {
    display: block;
  }
  .searchBarContainer {
    margin-top: 15px;
  }
}
