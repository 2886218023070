@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

#inputEmail {
  color: $DARK_GRAY;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0px !important;
}

.label {
  text-align: center;
  font-weight: 600;
  font-family: "Manrope";
  color: $BLACK2;
  font-size: 16px;
  line-height: 26px;

  @include responsiveProperty(
    (
      "padding-top": 8,
    )
  );
}

.actionContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.subLabel {
  width: 100%;
  font-weight: 600;
  font-family: "Manrope";
  color: $GRAY;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 6px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  gap: 20px;
}

.btnConfirm {
  padding: 7px 20px;
}

.BiggerScreenLabel {
  @include responsiveProperty(
    (
      "padding-top": 8,
      "font-size": 16,
      "line-height": 26,
    )
  );
}

.BiggerScreenSubLabel {
  @include responsiveProperty(
    (
      "padding-top": 6,
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.btn {
  @include responsiveProperty(
    (
      "width": 157,
      "font-size": 14,
      "line-height":22
    )
  );
}

.icon {
  @include responsiveProperty(
    (
      "width": 46,
      "height": 46,
    )
  );
}

.crossIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GRAY2;
  border: 1px solid $LIGHT_GRAY;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  @include responsiveProperty(
    (
      "margin-left": 24,
    )
  );
}

.labelTag {
  font-weight: 500;
  color: $GRAY;
  padding: 7px 10px 7px 10px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tagContainer {
    margin-left: calculatePixels(1200, 24) !important;
  }
  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .btn{
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 157) !important
  }
}

@media screen and (max-width: 600px) {
  .actionContainer {
    display: block;
  }

  .btn {
    margin-top: 20px;
    width: 100% !important;
  }
}
