@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.profilePic {
  border-radius: 50%;
  object-fit: cover;
  @include responsiveProperty(
    (
      "width": 100,
      "height": 100,
    )
  );
}

.mainContainer {
  @include responsiveProperty(
    (
      "padding": 24,
    )
  );
}

.imgContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_GREEN;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
}

.plusIconContainer {
  cursor: pointer;
  bottom: 2px;
  right: 32%;
  position: absolute;
  background-color: $LIGHT_BLUE2;
  border: 2px solid white !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsiveProperty(
    (
      "width": 18,
      "height": 18,
    )
  );
}

.labelTag {
  font-weight: 500;
  color: $GREEN_3;
  padding: 7px 10px 7px 10px !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.nameHeading {
  color: $DARK_GRAY;
  font-family: "Manrope";
  @include responsiveProperty(
    (
      "font-size": 18,
      "line-height": 26,
    )
  );
}

.headingContainer {
  display: flex;
  justify-content: center;
  font-weight: 500;

  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.infoContainer {
  border-top: 2px solid $LIGHT_GRAY;
  border-bottom: 2px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "margin-top": 24,
      "margin-bottom": 24,
      "padding-top": 16,
      "padding-bottom": 16,
    )
  );
}

.infoHeading {
  color: $DARK_GRAY;
  font-family: "Manrope";
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "margin-left": 8,
    )
  );
}

.singleInfoContainer {
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "margin-top": 10,
    )
  );
}

.infoheading2 {
  color: $GRAY;
  font-family: "Manrope";
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.info {
  color: $DARK_GRAY;
  font-family: "Manrope";
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.infoContainer3 {
  @include responsiveProperty(
    (
      "margin-top": 16,
    )
  );
}

.addedProfilePic {
  @include responsiveProperty(
    (
      "width": 32,
      "height": 32,
    )
  );
}

.buttonClassContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.btn {
  background-color: black !important;

  svg {
    margin-left: 10px;
    path {
      fill: white !important;
    }
  }
}

.invertBtn {
  background-color: black !important;

  svg {
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
    margin-left: 10px;

    path {
      fill: white !important;
    }
  }
}

.menuItem {
  font-family: "Manrope" !important;
  font-weight: 600;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 20,
      "font-size": 12,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.confirm {
  @include responsiveProperty(
    (
      "font-size": 12,
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-right": 35,
      "padding-left": 35,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .profilePic {
    width: calculatePixels(1200, 100) !important;
    height: calculatePixels(1200, 100) !important;
  }

  .mainContainer {
    padding: calculatePixels(1200, 24) !important;
  }

  .plusIconContainer {
    width: calculatePixels(1200, 18) !important;
    height: calculatePixels(1200, 18) !important;
  }

  .labelTag {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .nameHeading {
    font-size: calculatePixels(1200, 18) !important;
    line-height: calculatePixels(1200, 26) !important;
  }

  .headingContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .infoContainer {
    margin-top: calculatePixels(1200, 24) !important;
    margin-bottom: calculatePixels(1200, 24) !important;
    padding-top: calculatePixels(1200, 16) !important;
    padding-bottom: calculatePixels(1200, 16) !important;
  }

  .infoHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    margin-left: calculatePixels(1200, 8) !important;
  }

  .singleInfoContainer {
    margin-top: calculatePixels(1200, 10) !important;
  }

  .infoheading2 {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }

  .info {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .addedProfilePic {
    width: calculatePixels(1200, 32) !important;
    height: calculatePixels(1200, 32) !important;
  }

  .infoContainer3 {
    margin-top: calculatePixels(1200, 16) !important;
  }

  .buttonClassContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .cancel {
    padding-top: calculatePixels(1200, 20) !important;
    font-size: calculatePixels(1200, 12) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-right: calculatePixels(1200, 35) !important;
    padding-left: calculatePixels(1200, 35) !important;
  }

  .confirm {
    padding-top: calculatePixels(1200, 20) !important;
    font-size: calculatePixels(1200, 12) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-right: calculatePixels(1200, 35) !important;
    padding-left: calculatePixels(1200, 35) !important;
  }
}

@media screen and (max-width: 1200px) {
  .plusIconContainer {
    right: 25%;
  }
}

@media screen and (max-width: 900px) {
  .plusIconContainer {
    right: 12%;
  }
}

@media screen and (max-width: 935px) {
  .imgContainer {
    width: fit-content;
    margin: 0 auto;
  }

  .plusIconContainer {
    right: 6%;
  }
}
