@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.unchecked {
  border: 1.5px solid $GRAY;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;

  @include responsiveProperty(
    (
      "width": 13,
      "height": 13,
    )
  );
}

.checked {
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  background-color: $RED;

  display: flex;
  align-items: center;
  justify-content: center;

  @include responsiveProperty(
    (
      "width": 13,
      "height": 13,
    )
  );

  svg {
    @include responsiveProperty(
      (
        "width": 11,
        "height": 11,
      )
    );
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .unchecked {
    width: calculatePixels(1200, 13) !important;
    height: calculatePixels(1200, 13) !important;
  }

  .checked {
    width: calculatePixels(1200, 13) !important;
    height: calculatePixels(1200, 13) !important;
    svg {
      width: calculatePixels(1200, 11) !important;
      height: calculatePixels(1200, 11) !important;
    }
  }
}
