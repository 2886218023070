@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include responsiveProperty(
    (
      "font-size": 16,
      "line-height": 23,
    )
  );
}

.descriptionHeading {
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.description {
  font-weight: 400;
  color: $GRAY;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "margin-top": 10,
    )
  );
}

.amountSingleContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $LIGHT_GRAY;
  @include responsiveProperty(
    (
      "padding-top": 14,
      "padding-bottom": 14,
    )
  );
}

.amountContainer {
  background-color: $LIGHT_GRAY2;
  border-radius: 10px;
  @include responsiveProperty(
    (
      "padding": 20,
    )
  );
}

.amountHeading {
  font-weight: 500;
  color: $GRAY;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.amountHeading2 {
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.noteDescription {
  display: flex;
  align-items: center;
  color: $GRAY;
  font-weight: 400;
  align-items: start;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.finalContainer {
  @include responsiveProperty(
    (
      "margin-top": 10,
      "padding": 24
    )
  );
}

.noPaddingTop {
  padding-top: 0px !important;
}

.mainContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px;

  @include responsiveProperty(
    (
      "margin-top": 14,
    )
  );
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "padding-top": 14,
      "padding-bottom": 14,
      "padding-right": 24,
      "padding-left": 24,
    )
  );
}

.invoiceHeading {
  font-weight: 500;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.tableCell{
  font-weight: 500;
  color: $GRAY !important;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-left": 26,
      "padding-right":26 ,
    )
  );
}

.tableCell2{
  font-weight: 500;
  color: $DARK_GRAY !important;
  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
      "padding-top": 20,
      "padding-bottom": 20,
      "padding-left": 26,
      "padding-right":26 ,
    )
  );
}

.tableCell3{
  background-color: $LIGHT_GRAY2 !important;
  @include responsiveProperty(
    (
      "padding-top": 10,
      "padding-bottom": 10,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .backButton {
    span {
      font-size: calculatePixels(1200, 16) !important;
      line-height: calculatePixels(1200, 23) !important;
    }
  }
  .descriptionHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .description {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    margin-top: calculatePixels(1200, 10) !important;
  }
  .noteDescription {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .finalContainer {
    margin-top: calculatePixels(1200, 10) !important;
    padding:calculatePixels(1200, 24) !important;
  }
  .amountHeading,
  .amountHeading2 {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .amountSingleContainer {
    padding-top: calculatePixels(1200, 14) !important ;
    padding-bottom: calculatePixels(1200, 14) !important ;
  }
  .tableHeading {
    padding-top: calculatePixels(1200, 14) !important ;
    padding-bottom: calculatePixels(1200, 14) !important ;
    padding-right: calculatePixels(1200, 24) !important ;
    padding-left: calculatePixels(1200, 24) !important ;
  }
  .invoiceHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .mainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }
  .tableCell{
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    padding-top: calculatePixels(1200, 20) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 26) !important;
    padding-right: calculatePixels(1200, 26) !important;
  }
  .tableCell2{
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
    padding-top: calculatePixels(1200, 20) !important;
    padding-bottom: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 26) !important;
    padding-right: calculatePixels(1200, 26) !important;
  }
  .tableCell3{
    padding-top: calculatePixels(1200, 10) !important;
    padding-bottom: calculatePixels(1200, 10) !important;
  }
}

@media screen and (max-width:900px){
  .amountContainer{
    margin-top: 20px;
  }
  .tableCell, .tableCell2{
    min-width: 150px !important;
  }
}
