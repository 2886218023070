@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.mainContainer {
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.filterContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tagContainer {
  display: flex;
  align-items: center;
  background-color: $LIGHT_YELLOW;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  cursor: pointer;
}

.labelTag {
  font-weight: 500;
  color: $BROWN;
  padding: 7px 10px 7px 10px !important;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.resetBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $GRAY;
  cursor: pointer;
  margin-left: 15px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableMainContainer {
  border: 1px solid $LIGHT_GRAY;
  border-radius: 8px !important;
  @include responsiveProperty(
    (
      "margin-top": 24,
    )
  );
}

.tableHeading {
  display: flex;
  align-items: center;
  padding: 16px;
  width: auto;
  background-color: $LIGHT_GRAY2;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom: 1px solid $LIGHT_GRAY;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
}

.tableRow {
  td {
    border: none !important;
  }
}

.table {
  min-width: none !important;
  border: 1px solid $LIGHT_GRAY;
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: 0px !important;
}

.firstCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-left: none !important;
}

.lastCell {
  border: 1px solid $LIGHT_GRAY;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.tableCell {
  border-bottom: 1px solid $LIGHT_GRAY !important;
  border-right: 1px solid $LIGHT_GRAY;
  margin: 0px !important;
  font-weight: 400 !important;
  color: $GRAY !important;
  font-family: "Manrope" !important;
  display: table-cell !important;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

.tableCell2 {
  display: table-cell !important;
  font-weight: 400;
  color: $BLACK2;
  font-family: "Manrope" !important;
  max-width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

#tableRowId {
  td {
    border-bottom: 1px solid $LIGHT_GRAY !important;
  }
}

#tableBorderRight {
  border-right: 1px solid $LIGHT_GRAY !important;
}

.invoiceHeading {
  font-weight: 500;
  color: $DARK_GRAY;
  margin-left: 10px;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.btn {
  display: flex;
  align-items: center;
  border: 1px solid $LIGHT_GRAY;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: $GRAY;
  background-color: white !important;
  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "height": 30,
      "margin-left": 16,
    )
  );
}

.eyeIcon {
  @include responsiveProperty(
    (
      "margin-right": 8,
    )
  );
}

.withDrawMenuHeading {
  font-weight: 500;
  color: $BLACK;
  padding-bottom: 10px;
  border-bottom: 1.5px solid $LIGHT_GRAY;

  @include responsiveProperty(
    (
      "font-size": 14,
      "line-height": 22,
    )
  );
}

.withDrawSubMenuHeading {
  font-weight: 400;
  color: $BLACK;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
      "margin-top": 10,
      "width": 300,
    )
  );
}

.withDrawSubMenuDescription {
  font-weight: 500;
  color: $GRAY;
  display: flex;
  align-items: center;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 17,
      "margin-top": 10,
      "width": 300,
    )
  );
}

.icon {
  margin-right: 10px;
  display: flex;
  justify-content: start;
  align-items: start;
  height: 7vh;
}

.btnMenuContainer {
  display: flex;
  gap: 10px;

  @include responsiveProperty(
    (
      "margin-top": 20,
    )
  );
}

.cancel {
  color: $GRAY;
  background-color: $LIGHT_GRAY2;
  box-sizing: border-box;
  border: 1px solid $SELECT_GRAY;
  width: 100%;
  @include responsiveProperty(
    (
      "padding-right": 20,
      "padding-left": 20,
      "font-size": 12,
      "line-height": 18,
    )
  );

  &:hover {
    background-color: $LIGHT_GRAY2;
  }
}

.save {
  width: 100%;
  @include responsiveProperty(
    (
      "padding-right": 10,
      "padding-left": 10,
      "font-size": 12,
      "line-height": 18,
    )
  );
}

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;

}

.activeCheckbox {
  font-weight: 500;
  color: $DARK_GRAY;
  margin-left: 10px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

#noBorder{
  border-bottom: none !important;
}

.nonActiveCheckbox {
  font-weight: 500;
  color: $GRAY;
  margin-left: 10px;

  @include responsiveProperty(
    (
      "font-size": 12,
      "line-height": 16,
    )
  );
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .labelTag {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }

  .resetBtn {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableMainContainer {
    margin-top: calculatePixels(1200, 24) !important;
  }

  .tableCell {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
  }
  .tableCell2 {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .btn {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    height: calculatePixels(1200, 30) !important;
    margin-left: calculatePixels(1200, 16) !important;
  }
  .eyeIcon {
    margin-right: calculatePixels(1200, 6) !important;
  }
  .withDrawMenuHeading {
    font-size: calculatePixels(1200, 14) !important;
    line-height: calculatePixels(1200, 22) !important;
  }
  .withDrawSubMenuHeading {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 16) !important;
    margin-top: calculatePixels(1200, 10) !important;
    width: calculatePixels(1200, 300) !important;
  }
  .withDrawSubMenuDescription {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 17) !important;
    margin-top: calculatePixels(1200, 10) !important;
    width: calculatePixels(1200, 300) !important;
  }
  .btnMenuContainer {
    margin-top: calculatePixels(1200, 20) !important;
  }
  .save {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 18) !important;
  }
  .cancel {
    font-size: calculatePixels(1200, 12) !important;
    line-height: calculatePixels(1200, 18) !important;
  }
}


@media screen and (max-width:1200px){
  .actionContainer{
    display: block !important;
  }

  .tagContainer{
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 450px){
  .labelTag{
    font-size: 11px !important;
  }
}